import React, { useState, useRef, useCallback, useMemo } from 'react';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  Background,
  MiniMap,
  removeElements,
  getOutgoers,
  EdgeText,
  MarkerType
} from 'reactflow';
import 'reactflow/dist/style.css';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { toast } from 'react-hot-toast';

import '../style.css';
import Toolbox from '../component/Toolbox';
import UnderDevelopmentPage from '../../../utils/UnderDevelopmentPage';
import CanvasLeftNode from '../component/CanvasLeftNode';
import RightSideBar from './RightSideBar';
import CustomInputNode from './CustomInputNode';
import { AppBar, Box, Button, Dialog, Toolbar, Typography } from '@mui/material';
import { getConnectedEdges, getIncomers } from 'react-flow-renderer';
import { useLocation } from 'react-router-dom';
import CustomHandle from '../component/CustomHandle';
import CustomEdge from '../component/CustomEdge';
import FormSection from './FormSection';
import { removeDeletedNode } from './FlowSlice';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { SetSplitCounter, pushEditFlowData, pushFlowData } from './FlowSlice';

const initialNodes = [];

// const LeftCustomNode = ({ data }) => {
//   return <CanvasLeftNode data={data} />;
// };

const nodeTypes = { leftCustom: CustomHandle };
const edgeTypes = {
  custom: CustomEdge
};

const DnDFlow = () => {
  const [selectedNodes, setSelectNodes] = useState({});
  const [varKeys, setVarKeys] = useState([]);


  const createHttpConfigData = (data) => {
    console.log(data);
    let keys = Object.keys(data[0]);
    console.log('keys', keys);
    // let values = Object.values(data);
    let Obj = [];
    for (let j = 0; j < keys.length; j++) {
      Obj.push({
        key: keys[j],
        value: data[0][keys[j]]
      });
    }
    console.log(Obj);
    return Obj;
  };
  const createConfigdata = (data) => {
    console.log(data);
    const obj = [];

    const gettype = data[0].splittype;
    data.map((config) => {
      if (gettype == 'withcondition') {
        obj.push({
          key: 'expression',
          val1: config.val1,
          val2: config.val2,
          op: config.op,
          value: config.value
        });
      } else {
        obj.push({
          key: 'split',
          value: config.nodeid,
          name: config.branch
        });
      }
    });

    obj.push({
      key: 'Splittype',
      value: gettype
    });

    console.log('obj', obj);
    return obj;
  };
  const createwaitConfig = (data) => {
    console.log(data);
    let keys = Object.keys(data[0]);
    console.log('keys', keys);
    // let values = Object.values(data);
    let Obj = [];
    for (let j = 0; j < data.length; j++) {
      for (let i = 0; i < keys.length; i++) {
        Obj.push({
          key: keys[i],
          value: data[j][keys[i]]
        });
      }
    }
    console.log(Obj);
    let datasss = [Obj];
    console.log(datasss);
    const filteredValues = data.map((config) => {
      if (config.trigger === 'external') {
        return [
          {
            key: 'trigger',
            value: 'external'
          },
          {
            key: 'type',
            value: config.type
          }
        ];
      } else {
        return [
          {
            key: 'trigger',
            value: 'delay'
          },
          {
            key: 'type',
            value: config.type
          },
          {
            key: 'ms',
            value: config.ms
          }
        ];
      }
    });

    // Flatten the array of arrays into a single array
    const flattenedValues = filteredValues.flat();

    // Log or submit the flattened values
    console.log('Filtered and Flattened Form Values:', flattenedValues);
    return flattenedValues;
  };
  const createSyncConfigdata = (data) => {
    console.log(data);
    let keys = Object.keys(data[0]);
    console.log('keys', keys);
    // let values = Object.values(data);
    let Obj = [];

    for (let j = 0; j < data.length; j++) {
      console.log(data[j]['sync']);
      Obj.push({
        key: 'sync',
        value: data[j]['sync']
      });
    }
    Obj.push({
      key: 'syncType',
      value: data[0]['checkbox'] == true ? 'all' : 'any'
    });
    console.log(Obj);
    return Obj;
  };
  const createDtData = (data) => {
    console.lo;
    let payload = [
      {
        key: 'type',
        type: data[0].type,
        value: ''
      },
      {
        key: 'write',
        type: 'data',
        value: ''
      },
      {
        key: 'read',
        type: 'data',
        value: data[0].value
      }
    ];
    return payload;
  };
  const createScriptConfigData = (data) => {
    let ans = [
      {
        key: 'type',
        type: 'data',
        value: data[0].functionDef
      },
      {
        key: 'function',
        value: data[0].functionCall
      }
    ];
    return ans;
  };
  const createIterativeData = (data) => {
    let payload = [
      {
        key: 'workerId',
        value: data[0].workerid,
        count: data[0].count
      }
    ];
    return payload;
  };
  const createSubflow = (data) => {
    let obj = [
      {
        key: 'workerId',
        value: data[0].workerid
      }
    ];
    console.log('createSubflow data', obj);
    return obj;
  };
  const createsubflowVar = (data) => {
    let result = {};
    varKeys.forEach((key) => {
      if (data[0].hasOwnProperty(key)) {
        result[key] = data[0][key];
      }
    });

    return createHttpConfigData(data);

  };



  const createNotification = (data) => {
    console.log(data);
    let keys = Object.keys(data[0]);
    keys = keys.filter((data) => data !== 'key' && data !== 'value');
    console.log('keys', keys);
    // let values = Object.values(data);
    let Obj = [];
    for (let j = 0; j < keys.length; j++) {
      Obj.push({
        key: keys[j],
        value: data[0][keys[j]]
      });
    }
    let spread = data.slice(1)
    console.log(Obj);
    let newdata = {
      key: 'values',
      value: [

        {
          "key": data[0].key,
          "value": data[0].value

        },
        ...spread

      ]
    }
    Obj.push(newdata)

    return Obj
  }

  const createaDtData = (data) => {
    let keys = Object.keys(data[0]);
    let Obj = [];
    for (let j = 0; j < keys.length; j++) {
      Obj.push({
        key: keys[j],
        value: data[0][keys[j]]
      });
    }
  }

  const createSwitchdata = (data) => {
    console.log("288 :-", data);
    const obj = [];

    const variable = data[0].variable;
    if (variable !== undefined) {
      obj.push({
        key: 'variable',
        value: variable
      });
    }
    data.map((config) => {
      obj.push({
        "key": "case",
        "value": config.value ?? "",
        "val1": config.val1 ?? ""
      })
    })

    console.log('obj', obj);
    return obj;
  };


  const convertToJSON = (data) => {
    try {
      let jsonData = JSON.parse(data);
      return (jsonData);
    } catch (e) {
      console.log("In convertToJSON,data is string so retuning same");
      return data
    }
  }

  const createCustomTransfomrationData = (data) => {
    console.log("312 :- ", data);
    if (!data) return []
    let result;
    if (Array.isArray(data) && data.length > 0) {
      result = data.map(e => {
        return ({
          "key": "transform",
          "name": e.name ?? "",
          "value": convertToJSON(e.value) ?? ""
        })
      })
    }
    return result;
  }


  const getConfig = (name, data) => {
    console.log(name, data);
    if (data == undefined || (Array.isArray(data) && data.length == 0)) {
      data = [];
      return data;
    }
    switch (name) {
      case 'start':
        return data;
      case 'leaf':
        return data;
      case 'terminate':
        return data;
      case 'split':
        return createConfigdata(data);
      case 'http':
        return createHttpConfigData(data);
      case 'script':
        return createScriptConfigData(data);
      case 'sync':
        return createSyncConfigdata(data);
      case 'subFlow':
        return createSubflow(data);
      case 'db':
        return createHttpConfigData(data);
      case 'dt':
        return createDtData(data);
      case 'iterativeFlow':
        return createIterativeData(data);
      case 'wait':
        return createwaitConfig(data);
      case 'notification':
        console.log(data)
        return createNotification(data)
      case 'customtransformation':
        console.log("373 :-", data)
        return createCustomTransfomrationData(data)
      case 'switch':
        console.log("356", data);
        return createSwitchdata(data);
      default:
        throw new Error('Invalid name');
    }
  };

  const onSubmit = (id, data) => {
    console.log(data);
    let formdata = {};
    Object.keys(data).forEach((key) => {
      if (key === id) {
        formdata['sequence'] = data[key];
      } else if (key.startsWith(`${id}+`)) {
        const newKey = key.split('+')[1];
        formdata[newKey] = data[key];
      }
    });
    console.log('214', formdata);
    let dataObj = {};
    console.log('start Configs', selectedNodes);
    dataObj = {
      id: selectedNodes.id,
      name: selectedNodes.data.data.name,
      type: selectedNodes.data.data.type,
      assign: formdata.assign || [],
      variables:
        selectedNodes.data.data.type == 'subFlow'
          ? createsubflowVar(formdata.variables)
          : formdata.variables || [],
      configurations: getConfig(selectedNodes.data.data.type, formdata.configurations),
      sequence: formdata.sequence || [],
      position: selectedNodes.position
    };

    dispatch(pushFlowData(dataObj));

    console.log('start data', dataObj);

    toast.success("Data Has Been Saved!", {
      position: "top-right",
      style: {
        fontSize: "20px",
        padding: "16px",
        fontFamily: "Arial, sans-serif",
        fontWeight: "bold",
        color: "#fff",
        backgroundColor: "#4CAF50"
      },
    });
    // alert('Data Has Been Saved!');
  };
  const reactFlowWrapper = useRef(null);
  const [elements, setElements] = useState([]);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [values, setValues] = useState(null);
  const settingValues = (data) => {
    setValues(data);
  };
  const location = useLocation();
  const payload = location.state;
  const dispatch = useDispatch();

  // const nodeTypes = useMemo(() => ({ textUpdater:CustomHandle  }), []);

  const [showStart, setShowStart] = useState(false);

  const onConnect = useCallback(
    (params) => {
      const edge = { ...params, type: 'custom' };
      setEdges((eds) => addEdge(edge, eds));
    },
    [setEdges]
  );

  // const onElementsRemove = (elementsToRemove) => {
  //   setElements((els) => removeElements(elementsToRemove, els));
  // };

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);
  let id = 0;
  const getId = (nodeId) => `${nodeId}${id++}`;

  const onNodesDelete = useCallback(
    (deleted) => {
      console.log(deleted);
      dispatch(removeDeletedNode(deleted));
      setEdges(
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);

          const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge));

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({ id: `${source}->${target}`, source, target }))
          );

          return [...remainingEdges, ...createdEdges];
        }, edges)
      );
    },
    [nodes, edges]
  );

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      // Parse the node data from the dropped item
      const nodeData = JSON.parse(event.dataTransfer.getData('application/node'));




      console.log("Node has been Dropped", nodeData)


      console.log("Node has been Dropped", nodeData)

      // Log the node label for debugging

      if (nodes.length == 0 && nodeData.label.toLowerCase() !== 'start') {
        alert('WorkFlow First Node should be Start.');
      }

      // Check if 'showStart' is true and the dropped node is a 'start' node
      else if (showStart && nodeData.label.toLowerCase() === 'start') {
        // If a 'start' node is already present, show an alert
        alert('Only one Start node is allowed');
      } else {
        // Check if the dropped node is a 'start' node and update 'showStart' accordingly
        if (nodeData.label.toLowerCase() === 'start') {
          setShowStart(true);
        }

        // Get the type of the dropped node
        const type = event.dataTransfer.getData('application/reactflow');
        console.log('Type of dropped node is ...', type, nodeData);

        // If the type is undefined or empty, return
        if (typeof type === 'undefined' || !type) {
          return;
        }

        // Convert the screen position to flow position
        const position = reactFlowInstance.screenToFlowPosition({
          x: event.clientX,
          y: event.clientY
        });

        // Create a new node object
        const newNode = {
          id: getId(nodeData.data.id),
          type,
          position,
          data: nodeData
        };

        console.log(newNode);

        // Update the nodes array with the new node
        setNodes((nds) => nds.concat(newNode));
      }
    },
    [reactFlowInstance, showStart]
  );

  const jsonData = {
    nodeData: nodes,
    edgeData: edges
  };

  const downloadJson = () => {
    const jsonString = JSON.stringify(jsonData, null, 2);

    const blob = new Blob([jsonString], { type: 'application/json' });
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = 'data.json';
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const [open, setOpen] = useState(false);
  const formData = useSelector((state) => state);
  const submit = () => {
    let data = formData.store.reactflow.flowData;
    axios
      .post(
        `${process.env.REACT_APP_WORKFLOW}/workerFlow/submitworkflow`,

        {
          id: payload.metaData.metaid,
          name: payload.metaData.metaname,
          description: payload.metaData.metadesc,
          variables: payload.metaData.variablesMeta,
          activities: data,
          status: 'Draft',
          version: 'v1.1'
        },
        {}
      )
      .then((res) => {
        console.log();
        alert('WorkFlow Has Been saved..👍');
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#4c6fff',
          // width: '90vw',
          height: '50px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center', // Aligns children to the ends
          alignItems: 'center', // Centers items vertically
          px: 5, // Add padding on left and right
          position: 'sticky'
        }}>

        <Typography sx={{ color: 'white', fontWeight: 'bold', fontFamily: 'Roboto', textAlign: 'center' }}>
          Workflow Name : {payload.metaData.metaname ? payload.metaData.metaname : 'New WorkFlow'}
        </Typography>

        <Button onClick={submit} color="success" variant="contained" size="small" sx={{ mr: 10, alignSelf: 'right' }}>
          Save WorkFlow
        </Button>
      </Box>
      <Box className="dndflow">

        <Toolbox />

        <ReactFlowProvider>


          <div className="reactflow-wrapper" ref={reactFlowWrapper}>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              onInit={setReactFlowInstance}
              onNodesDelete={onNodesDelete}
              edgeTypes={
                'step'
              }
              onDrop={onDrop}
              onDragOver={onDragOver}
              nodeTypes={nodeTypes}
              // edgeTypes={edgeTypes}
              // connectionLineType={'Straight '}
              onNodeClick={(event, node) => {
                console.log('node', node);
                setOpen(true);
                setSelectNodes(node);
              }}
              fitView>
              <Controls />
              <EdgeText />
              <Background color="#ccc" variant="cross" />
            </ReactFlow>
          </div>

          <Offcanvas
            show={open}
            onHide={() => {
              setOpen(false);
              console.log("It's closed", values);
              onSubmit(selectedNodes.id, values);
            }}
            placement="end"
            name="end">
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
              <FormSection
                selectedNodes={selectedNodes}
                formSubmit={onsubmit}
                valuesSet={setValues}
              />
            </Offcanvas.Body>
          </Offcanvas>
          {/* {open && (
            <div className="form-section-scrollable" style={{ width: '400px' }}>
              <FormSection selectedNodes={selectedNodes} formSubmit={onsubmit} />
            </div>
          )} */}
        </ReactFlowProvider>
      </Box>
    </>
  );
};

export default DnDFlow;
