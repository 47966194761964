import { ThemeProvider } from '@mui/material';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import theme from './configs/theme';
import ErrorBoundary from './modules/common/error/ErrorBoundary';
import Pages from './pages/index';
import store from './store/store';
import { LicenseManager } from 'ag-grid-enterprise';
import 'bootstrap/dist/css/bootstrap.min.css';
LicenseManager.setLicenseKey(
  'CompanyName=Real Variable Digital AssetServices Private Limited,LicensedGroup=Real Variable,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-032136,SupportServicesEnd=22_March_2024_[v2]_MTcxMTA2NTYwMDAwMA==c97f15e3f038787af26a2fef71e443ae'
);
const queryClient = new QueryClient();
function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient} contextSharing={true}>
        <ThemeProvider theme={theme}>
          <HashRouter>
            <ErrorBoundary>
              <DndProvider backend={HTML5Backend}>
                <Toaster />
                <Pages />
              </DndProvider>
            </ErrorBoundary>
          </HashRouter>
        </ThemeProvider>
      </QueryClientProvider>
    </Provider>
  );
}
ReactDOM.createRoot(document.getElementById('root')).render(<App />);
