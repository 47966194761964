import { Box, Typography } from '@mui/material';

import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import db from '../../../public/flowIcons/db.png';
import http from '../../../public/flowIcons/http.png';
import leaf from '../../../public/flowIcons/leaf.png';
import split from '../../../public/flowIcons/split.png';
import script from '../../../public/flowIcons/script.png';
import start from '../../../public/flowIcons/start.png';
import subflow from '../../../public/flowIcons/subflow.png';
import sync from '../../../public/flowIcons/sync.png';
import value from '../../../public/flowIcons/value.png';
import wait from '../../../public/flowIcons/wait.png';
import condition from '../../../public/flowIcons/condition.png';
import iterative from '../../../public/flowIcons/iterative.png';

import switches from '../../../public/flowIcons/switch.png';
import notification from '../../../public/flowIcons/notify.png';
import terminate from '../../../public/flowIcons/twitter.png';
import customtransformation from '../../../public/flowIcons/custom.png';

const Toolbox = () => {
  const onDragStart = (event, nodeType, node) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.setData('application/node', JSON.stringify(node));
    event.dataTransfer.effectAllowed = 'move';
  };
  const splitIcon = () => <h6>split it</h6>;
  const nodeTypes = { textUpdater: splitIcon };
  const Splitcounter = useSelector((state) => state.store.reactflow.splitCounter);
  const syncCounter = useSelector((state) => state.store.reactflow.syncCounter);

  // console.log("Split Counters", parseInt(Splitcounter));

  let counter = parseInt(Splitcounter) + 1;
  let SyncCounter = parseInt(syncCounter) + 1;

  // console.log("Split Counters", counter);

  useEffect(() => {
    // console.log("counter is ....",counter)
  }, [Splitcounter]);

  const nodesInfo = [
    {
      label: 'Start',
      nodeType: 'leftCustom',
      type: nodeTypes,
      style: { stroke: 'grey' },
      data: {
        id: 'automatic',
        name: 'name1',
        type: 'start',
        location: 'source',
        handles: [{ position: 'right', handleType: 'source', isConnectable: 2 }]
      }
    },
    {
      label: 'Split',
      nodeType: 'leftCustom',
      type: 'splitNode',
      style: { stroke: 'grey' },
      data: {
        id: 'nodeId7',
        name: 'name7',
        type: 'split',
        location: 'default',
        handles: [
          { position: 'left', handleType: 'target', isConnectable: 1000 },
          { position: 'right', handleType: 'source', isConnectable: counter + 1 }
        ]
      }
    },
    {
      label: 'Script',
      nodeType: 'leftCustom',
      style: { stroke: 'grey' },
      data: {
        id: 'nodeId85',
        name: 'nodeId85',
        type: 'script',
        location: 'default',
        handles: [
          { position: 'left', handleType: 'target', isConnectable: 100 },
          { position: 'right', handleType: 'source', isConnectable: 100 }
        ]
      }
    },
    {
      label: 'End',
      nodeType: 'leftCustom',
      style: { stroke: 'grey' },
      data: {
        id: 'nodeId4',
        name: 'error',
        type: 'leaf',
        location: 'target',

        handles: [{ position: 'left', handleType: 'target', isConnectable: 1000 }]
        // { position: 'right', handleType: 'source', isConnectable: 3 }
      }
    },
    {
      label: 'Terminate',
      nodeType: 'leftCustom',
      style: { stroke: 'grey' },
      data: {
        id: 'nodeId10',
        name: 'error',
        type: 'terminate',
        location: 'target',

        handles: [{ position: 'left', handleType: 'target', isConnectable: 1000 }]
        // { position: 'right', handleType: 'source', isConnectable: 3 }
      }
    },
    {
      label: 'Join',
      nodeType: 'leftCustom',
      style: { stroke: 'grey' },
      data: {
        id: 'syncNode',
        location: 'default',
        type: 'sync',
        name: 'sync',
        handles: [
          { position: 'left', handleType: 'target', isConnectable: SyncCounter },
          { position: 'right', handleType: 'source', isConnectable: 10000 }
        ]
      }
    },
    {
      // label: (
      //   <>
      //     <img src={hamburgerIcon} width={14} height={14} />
      //   </>
      // ),
      label: 'wait',
      nodeType: 'leftCustom',
      style: { stroke: 'grey' },
      data: {
        id: 'waitNode',
        location: 'source',
        name: 'wait node',
        name: 'wait node',
        type: 'wait',
        handles: [
          { position: 'left', handleType: 'target', isConnectable: 2 },
          { position: 'right', handleType: 'source', isConnectable: 3 }
        ]
      }
    },
    {
      label: 'Http',
      nodeType: 'leftCustom',
      style: { stroke: 'grey' },
      data: {
        id: 'httpNode',
        location: 'source',
        name: 'http',
        name: 'http',
        type: 'http',
        handles: [
          { position: 'left', handleType: 'target', isConnectable: 2 },
          { position: 'right', handleType: 'source', isConnectable: 3 }
        ]
      }
    },
    {
      label: 'SubFlow',
      nodeType: 'leftCustom',
      style: { stroke: 'grey' },
      data: {
        name: 'name2',
        type: 'subFlow',
        id: 'subFlowNode',
        location: 'source',
        handles: [
          { position: 'left', handleType: 'target', isConnectable: 2 },
          { position: 'right', handleType: 'source', isConnectable: 3 }
        ]
      }
    },
    {
      label: 'Db',
      nodeType: 'leftCustom',
      style: { stroke: 'grey' },
      data: {
        name: 'dbname',
        type: 'db',
        id: 'DBnode',
        location: 'source',
        handles: [
          { position: 'left', handleType: 'target', isConnectable: 100 },
          { position: 'right', handleType: 'source', isConnectable: 100 }
        ]
      }
    },
    {
      label: 'Data Transformation',
      nodeType: 'leftCustom',
      style: { stroke: 'grey' },
      data: {
        id: 'dt',
        location: 'source',
        name: 'dt',
        type: 'dt',
        handles: [
          { position: 'left', handleType: 'target', isConnectable: 2 },
          { position: 'right', handleType: 'source', isConnectable: 3 }
        ]
      }
    },

    {
      label: 'IterativeFlow',
      nodeType: 'leftCustom',
      style: { stroke: 'grey' },
      data: {
        id: 'nodeId3',
        name: 'name2',
        type: 'iterativeFlow',
        location: 'source',
        handles: [
          { position: 'left', handleType: 'target', isConnectable: 2 },
          { position: 'right', handleType: 'source', isConnectable: 3 }
        ]
      }
    },
    {
      label: 'Switch',
      nodeType: 'leftCustom',
      style: { stroke: 'grey' },
      data: {
        id: 'nodeId9',
        name: 'switch',
        type: 'switch',
        location: 'source',
        handles: [
          { position: 'left', handleType: 'target', isConnectable: 100 },
          { position: 'right', handleType: 'source', isConnectable: 100 }
        ]
      }
    },
    {
      label: 'Notification',
      nodeType: 'leftCustom',
      style: { stroke: 'grey' },
      data: {
        id: 'nodeId8',
        name: 'notification',
        type: 'notification',
        location: 'source',
        handles: [
          { position: 'left', handleType: 'target', isConnectable: 2 },
          { position: 'right', handleType: 'source', isConnectable: 3 }
        ]
      }
    },
    {
      label: 'Customtransformation',
      nodeType: 'leftCustom',
      style: { stroke: 'grey' },
      data: {
        id: 'nodeId19',
        name: 'customtransformation',
        type: 'customtransformation',
        location: 'source',
        handles: [
          { position: 'left', handleType: 'target', isConnectable: 2 },
          { position: 'right', handleType: 'source', isConnectable: 3 }
        ]
      }
    }
  ];

  const icons = {
    Start: start,
    Split: split,
    Script: script,
    End: leaf,
    Join: sync,
    wait: wait,
    Http: http,
    SubFlow: subflow,
    Db: db,
    'Data Transformation': value,
    Condition: condition,
    IterativeFlow: iterative,
    Switch: switches,
    Notification: notification,
    Terminate: terminate,
    Customtransformation: customtransformation
  };

  const desc = {
    Start: 'Beginning of flow execution. Trigger a flow using a HTTP or Kafka message',
    Split: 'Split flow into different parallel branches',
    Script:
      'Evaluate node allows to run JavaScript code and  allows to conditionally branch the flow based on output.',
    End: 'Denotes end the flow execution',
    Join: 'Merge split  branched with Synchronization',
    wait: 'Flow execution gets paused before proceeding to the next node',
    Http: 'Get data from any external systems and services using RESTFul API',
    SubFlow: 'Invoke one flow within a service from another flow',
    Db: 'Get data from external databases by executing a selected query.',
    'Data Transformation': 'Transform data from JSON to XML or Vice Versa',
    Condition: 'Logical branching block  accepts a condition and returns a Boolean value.',
    IterativeFlow: 'iterative Node',
    Notification: 'notification Node',
    Switch: 'switch Node',
    Terminate: 'terminate Node',
    Customtransformation: 'Transform Node'
  };

  return (
    <aside
      style={{
        width: '300px',
        height: '100vh',
        overflowY: 'auto',
        borderRight: '1px solid #ccc',
        padding: '10px',
        backgroundColor: '#f8f9fa'
      }}>
      <div className="mb-2 text-2xl" style={{ fontFamily: 'roboto' }}>
        General
      </div>
      <Box>
        {nodesInfo.map((node) => {
          return (
            <Box
              className="relative  p-2 rounded cursor-grab  bg-white mb-4 flex items-center text-center"
              sx={{ border: '1px solid orange' }}
              onDragStart={(event) => onDragStart(event, node?.nodeType, node)}
              draggable
              key={node.data.id}>
              {icons[node.label] && (
                <img
                  src={icons[node.label]}
                  alt={node.label}
                  width={45}
                  height={45}
                  className="mr-2"
                />
              )}
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <p
                  style={{
                    textAlign: 'justify',
                    fontSize: '16px',
                    // textTransform:'uppercase',
                    fontWeight: 'bold',
                    color: 'black',
                    marginBottom: '2px'
                  }}>
                  {node?.label}
                </p>
                <p
                  style={{
                    textAlign: 'justify',
                    fontSize: '14px',
                    // textTransform:'uppercase',
                    fontWeight: 600,
                    color: 'black'
                  }}>
                  {desc[node?.label]}
                </p>
              </Box>
            </Box>
          );
        })}
      </Box>
    </aside>
  );
};

export default Toolbox;
