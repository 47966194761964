import React, { useState } from 'react';
import classNames from 'classnames';
import { noop } from 'lodash';
import { useNavigate } from 'react-router-dom';
import keycloak from '../../../Keycloak';
import sidebarConst from '../../../modules/common/sidebar/constants/sidebar';
import realwareLogo from '../../../public/RealWare-Web3-Final-Logo.png';
import backLogo from '../../../public/icons/backLogo.svg';
import userLogo from '../../../public/icons/users.png';
import hamburgerIcon from '../../../public/icons/hamburger.png'; // Import the hamburger menu icon

const SideBar = ({ page = 'Dashboard', currentContext = '', onMenuItemClick = noop }) => {
  const currentData = sidebarConst[page];
  const currentSelected = currentContext[page];
  const navigate = useNavigate();
  // const [isMenuOpen, setIsMenuOpen] = useState(false); // State to track if the menu is open or closed

  const handleMenuItemClick = (element) => {
    // toggleMenu(); // Close the menu when a menu item is clicked
    onMenuItemClick({ contextName: element.contextName, title: element.title });
  };

  return (
    <div className={`w-[280px] bg-white px-[25px] py-[48px] flex flex-col sticky top-0 h-[100vh]`}>
      <div className="cursor-pointer" onClick={() => navigate('/dashboard')}>
        {/* <img src={realwareLogo} alt="Realware Logo" /> */}
        <img src={realwareLogo} alt="Realware Logo" />
      </div>
      <div className="py-[30px] flex flex-col justify-between flex-1">
        <div className="flex flex-col justify-between">
          <div className="mb-[46px]">
            {currentData.map((element) => {
              const selectedClass = classNames(
                'cursor-pointer px-[28px] py-[14px] my-[7px] flex hover:bg-[#E4E3FF] hover:text-[#4C49ED] rounded-[6px] text-[#A1A0BD] items-center',
                {
                  'bg-[#E4E3FF] text-black font-semibold': element.title === currentSelected,
                  'bg-gray-400 hover:bg-gray-400 text-black font-semibold': !!element.isDisabled
                }
              );
              return (
                <div
                  key={element.title} // Add key prop for React lists
                  className={selectedClass}
                  onClick={() => handleMenuItemClick(element)}>
                  <div className="pr-[16px]">
                    <img height={'28px'} width={'28px'} src={element.imageComponent} alt="" />
                  </div>
                  <div>{element.title}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div>
          {keycloak.authenticated && (
            <div className="cursor-pointer px-[28px] py-[14px] my-[7px] flex items-center rounded-[6px]">
              <div className="pr-[16px]">
                <img height={'40px'} width={'40px'} src={userLogo} alt="" />
              </div>
              <div className="text-[#A1A0BD] text-[20px]">
                {keycloak.getUserData().preferred_username}
              </div>
            </div>
          )}
          <div
            onClick={() => keycloak.logout()}
            className="cursor-pointer px-[28px] py-[14px] my-[7px] flex hover:bg-[#E4E3FF] hover:text-[#4C49ED] rounded-[6px]">
            <div className="pr-[16px]">
              <img src={backLogo} alt="" />
            </div>
            <div className="text-[#A1A0BD] ">Log out</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
