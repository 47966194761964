import React, { useState } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import keycloak from '../../../Keycloak';
import realwareLogo from '../../../public/RealWare-Web3-Final-Logo.png';
import backLogo from '../../../public/icons/backLogo.svg';
import userLogo from '../../../public/icons/users.png';
import hamburgerIcon from '../../../public/icons/HamburgerIcon.svg';
import SideElement from './SideElement';

const SideNavigation = ({ componentSchema = [] }) => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(true); // State to track if the menu is open or closed

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <div
        className={`w-[280px] bg-white px-[25px] py-[47px] flex flex-col sticky top-0 h-[100vh] ${
          isMenuOpen ? '' : 'hidden'
        }`}>
        <div className="cursor-pointer" onClick={() => navigate('/dashboard')}>
          <img src={realwareLogo} alt="Realware Logo" />
        </div>
        <div className="py-[30px] flex flex-col justify-between flex-1">
          <div className="flex flex-col justify-between">
            <div className="mb-[46px]">
              {componentSchema.map((element, index) => {
                return <SideElement key={index} elementData={element} />;
              })}
            </div>
          </div>
          <div>
            {keycloak.getUserData().preferred_username && (
              <div className="cursor-pointer px-[28px] py-[14px] my-[7px] flex items-center rounded-[6px]">
                <div className="pr-[16px]">
                  <img height={'40px'} width={'40px'} src={userLogo} alt="" />
                </div>
                <div className="text-[#A1A0BD] text-[20px]">
                  {keycloak.getUserData().preferred_username}
                </div>
              </div>
            )}
            <div
              onClick={() => keycloak.logout()}
              className="cursor-pointer px-[28px] py-[14px] my-[7px] flex hover:bg-[#E4E3FF] hover:text-[#4C49ED] rounded-[6px]">
              <div className="pr-[16px]">
                <img src={backLogo} alt="" />
              </div>
              <div className="text-[#A1A0BD] ">Log out</div>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed top-0 right-0 m-4 z-[1000]" onClick={toggleMenu}>
        <img src={hamburgerIcon} alt="Hamburger Menu Icon" width={25} height={15} />
      </div>
    </>
  );
};

export default SideNavigation;
