import { Typography } from '@material-ui/core';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled
} from '@mui/material';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Paper,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import {
  Close as CloseIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Visibility as VisibilityIcon
} from '@mui/icons-material';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { pushEditFlowData, pushMetaData, pushVersioning, pushWorkFlowList } from './FlowSlice';
import { useNavigate } from 'react-router-dom';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import 'reactflow/dist/style.css';
import { useFieldArray, useForm } from 'react-hook-form';
import axios from 'axios';
import { version } from 'joi';
import DataTable from 'react-data-table-component';
import '../style.css'
import { toast } from 'react-hot-toast';
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)'
    }
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200
    })
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box'
  }
}));

export default function ShowCanvas() {
  const nav = useNavigate();
  const [canvasList, setCanvasList] = useState([]);
  console.log("canvas Listtttttttttttttttttttt", canvasList)
  const [open, setOpen] = useState(false);

  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('md');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // MetaData

  const [wfId, setWfId] = useState('');
  const [wfDesc, setWfDesc] = useState('');
  const [wfName, setwfName] = useState('');

  const handlewfId = (e) => {
    setWfId(e.target.value);
  };
  const handlewfDesc = (e) => {
    setWfDesc(e.target.value);
  };
  const handlewfName = (e) => {
    setwfName(e.target.value);
  };

  useEffect(() => {
    getCanvases();
  }, []);

  const createData = (data, action) => {
    let obj = {
      id: data.id,
      name: data.name,
      description: data.description,
      variables: data.variables
    };
    let version = {
      status: data.status,
      version: data.version
    };
    console.log('verion........', version);
    dispatch(pushVersioning(version));
    dispatch(pushMetaData(obj));
    console.log(data.activities);
    if (data.activities[0].hasOwnProperty('position') == true) {
      nav('/viewcanvas', { state: { payload: data.activities, action: action } });
      dispatch(pushEditFlowData(data.activities));
    } else {
      alert("This node doesn't has Positions.");
    }
  };

  const getCanvases = () => {
    const workflowList = [];
    let obj = {};
    console.log(`${process.env.REACT_APP_WORKFLOW}/workerFlow/workFlowData`);

    axios
      .get(`${process.env.REACT_APP_WORKFLOW}/workerFlow/workFlowData`, {})
      .then((res) => {
        setCanvasList(res.data.result);
        res.data.result.map((data, index) => {
          obj = {
            show: data.id + '_' + (data.version ? data.version : 'v1.1'),
            id: data.id,
            version: data.version ? data.version : 'v1.1'
          };
          workflowList.push(obj);
        });

        console.log(res.data.result);
        dispatch(pushWorkFlowList(workflowList));
      })
      .catch((err) => console.log(err));
  };

  const dispatch = useDispatch();

  const onSubmit = (data, e) => {
    let metaData = {
      metaid: wfId,
      metaname: wfName,
      metadesc: wfDesc,
      variables: data.metadata
    };

    axios
      .post(`${process.env.REACT_APP_WORKFLOW}/workerFlow/submitMetaData`, {
        id: wfId
      })
      .then((res) => {
        // console.log(res)
        if (res.data.status.code != 'failed') {
          console.log('The MetaData....', metaData);
          alert('Meta Data Has Been Submitted');

          dispatch(pushMetaData(metaData));
          sessionStorage.setItem('metaData', metaData);
          nav('/canvas', { state: { metaData } });
        } else {
          alert(`Wokrflow Id : "${wfId}" already existed`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
    trigger,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      metadata: [{ key: '', value: '' }]
    }
  });

  const {
    fields: meta,
    append: appendMeta,
    remove: removeMeta
  } = useFieldArray({
    control,
    name: 'metadata'
  });

  const excueteCanvas = (metaid) => {
    axios
      .get('http://192.168.1.140:3023/workerFlow/' + metaid, {})
      .then((res) => alert('WorkFlow Has Been proceesed..🙌'))
      .catch((err) => console.log(err));
  };

  // const [isPublished, setIsPublished] = useState();

  // const updateWorkflow = () =>{
  //   axios.post("https://api.config.innovation.realware.app/workerFlow/UpdateStatus",{

  //   })
  // }

  // Handle change event
  const handleUpdate = (event, data) => {
    const url = `${process.env.REACT_APP_WORKFLOW}/workerFlow/UpdateStatus`;
    console.log({
      id: data.id,
      version: data.version,
      status: event
    });
    axios
      .post(url, {
        id: data.id,
        version: data.version,
        status: event
      })
      .then((res) => {
        alert(res.data.status.message);
        getCanvases();
      })
      .catch((err) => console.log(err));
  };

  function ActionMenu({ row, onAction }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleAction = (action) => {
      if (action === "Delete") {
        const confirmDelete = window.confirm("Are you sure you want to delete this item?");
        if (confirmDelete) {
          handleDelete(row.version, row.id); // Pass version and id of the row
        }
      } else if (action === "View" || action === "Edit") {
        createData(row, action);
      } else if (action === "Publish" || action === "Unpublish") {
        handleUpdate(action, row);
      } else if (action === "ShowUrl") {
        handleShowUrl(row.version, row.id); // Generate and copy URL
      }
      handleClose();
    };
    const handleShowUrl = (version, id) => {
      const url = `${process.env.REACT_APP_WORKFLOW}/workerFlow/run/${id}/${version}`;
      navigator.clipboard.writeText(url)
        .then(() => {
          toast.success('URL copied to clipboard!');
        })
        .catch((err) => {
          console.error('Failed to copy URL:', err);
          toast.error('Failed to copy URL.');
        });
    };

    const handleDelete = async (version, id) => {
      try {
        const url = `${process.env.REACT_APP_WORKFLOW}/workerFlow/delete/${version}/${id}`;
        await axios.delete(url);
        alert('Item deleted successfully.');
        getCanvases();
      } catch (error) {
        console.error('Error deleting item:', error);
        alert('Failed to delete item.');
      }
    };

    return (
      <div>
        <IconButton
          aria-controls="action-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="action-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleAction('View')}>View</MenuItem>
          <MenuItem onClick={() => handleAction('Edit')}>Edit</MenuItem>
          <MenuItem onClick={() => handleAction('Publish')}>Publish</MenuItem>
          <MenuItem onClick={() => handleAction('Unpublish')}>Unpublish</MenuItem>
          <MenuItem onClick={() => handleAction('Delete')}>Delete</MenuItem>
          <MenuItem onClick={() => handleAction('ShowUrl')}>Copy URL</MenuItem>
        </Menu>
      </div>
    );
  }
  const customStyles = {
    headCells: {
      style: {
        fontSize: '14px',
        fontFamily: 'Roboto',
        fontWeight: '600',
        color: '#333',
      },
    },
  };

  const columns = [
    {
      name: 'Business Process Id',
      selector: (row) => row.id,
      sortable: true,
      minWidth: '250px',
      style: {
        fontSize: '14px',
        fontFamily: 'Roboto',
        fontWeight: '400'
      }
    },
    {
      name: 'Business Process Name',
      selector: (row) => row.name,
      sortable: true,
      minWidth: '200px',
      style: {
        fontSize: '14px',
        fontFamily: 'Roboto',
        fontWeight: '400'
      }
    },
    {
      name: 'Description',
      selector: (row) => row.description,
      sortable: true,
      minWidth: '250px',
      style: {
        fontSize: '14px',
        fontFamily: 'Roboto',
        fontWeight: '400'
      }
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: true,
      minWidth: '150px',
      style: {
        fontSize: '14px',
        fontFamily: 'Roboto',
        fontWeight: '400'
      }
    },
    {
      name: 'Version',
      selector: (row) => row.version,
      sortable: true,
      minWidth: '100px',
      style: {
        fontSize: '14px',
        fontFamily: 'Roboto',
        fontWeight: '400'
      }
    },
    {
      name: 'Action',
      selector: '',
      sortable: false,
      minWidth: '150px',
      cell: (row) => (
        <ActionMenu
          row={row}
          onAction={(action, rowData) => {
            createData()
            console.log(action, rowData);
          }}
        />
      )
    }
  ];

  return (
    <div className="dndflow">
      <div style={{ margin: 10 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
          <Typography sx={{ fontSize: '24px' }}>Business Process List</Typography>
          <Button variant="contained" color="success" onClick={() => setOpen(true)}>
            Create Business Process
          </Button>
        </Box>
        <Box>
          <DataTable
            columns={columns}
            pagination
            data={canvasList.map((item, index) => ({
              ...item,
              uniqueKey: `${item.id}-${index}`
            }))}
            keyField="uniqueKey"
            paginationPerPage={10}
            dense
            selectableRowsHighlight
            highlightOnHover
            customStyles={customStyles}
          />
        </Box>

        {open ? (
          <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={open} onClose={handleClose}>
            <Box sx={{ m: 5 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box>
                  <DialogTitle
                    sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6">Business Process Details </Typography>
                    <IconButton color="warning" onClick={handleClose}>
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <TextField
                      label="Enter WorkflowId"
                      variant="outlined"
                      name="wfId"
                      size="small"
                      onChange={handlewfId}
                      sx={{ marginRight: 2, marginBottom: 2 }}
                      value={wfId}
                      required
                    />
                    <TextField
                      label="Enter Workflow Name"
                      variant="outlined"
                      name="wfId"
                      size="small"
                      onChange={handlewfName}
                      sx={{ marginRight: 2, marginBottom: 2 }}
                      value={wfName}
                      required
                    />
                    <TextField
                      label="Enter Workflow Description"
                      variant="outlined"
                      size="small"
                      onChange={handlewfDesc}
                      sx={{ marginRight: 2, marginBottom: 2 }}
                      value={wfDesc}
                      required
                    />
                  </Box>
                  {meta.map((field, index) => (
                    <Box key={field.id}>
                      <TextField
                        label="key"
                        variant="outlined"
                        {...register(`metadata[${index}].key`)}
                        size="small"
                        sx={{ marginRight: 2, marginBottom: 2 }}
                      />
                      <TextField
                        label="value"
                        variant="outlined"
                        {...register(`metadata[${index}].value`)}
                        size="small"
                        sx={{ marginRight: 2, marginBottom: 2 }}
                      />
                      <IconButton color="error" onClick={() => removeMeta(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ))}
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => appendMeta({ key: '', value: '' })}
                    sx={{ mb: 3 }}>
                    ADD
                  </Button>
                </Box>
                <Button variant="contained" color="success" type="submit">
                  Submit
                </Button>
                {/* </Box> */}
              </form>
            </Box>
          </Dialog>
        ) : null}
      </div>
    </div>
  );
}
