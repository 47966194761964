import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';

import React, { useCallback, useEffect, useState } from 'react';

import { pushEditFlowData } from './FlowSlice';
import { useDispatch, useSelector } from 'react-redux';

import FormCreate from './FormCreate';

import axios from 'axios';

import {
  Close as CloseIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Visibility as VisibilityIcon
} from '@mui/icons-material';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { values } from 'lodash';

const ViewFormSection = React.memo(({ selectedNodes, action }) => {
  const [initialValues, setInitialValues] = useState({});
  const formData = useSelector((state) => state);
  const payload = formData.store.reactflow.editFlow;
  // console.log(payload);
  const metaData = useSelector((state) => state.store.reactflow.metaData);

  const dispatch = useDispatch();
  const [status, setStatus] = useState('success');
  const [selectedOption, setSelectedOption] = useState('');
  const [edit, setEdit] = useState(action == 'View' ? false : true);

  // const [workflowData, setWorkflowdata] = useState([]);

  // const getworkflowList = () => {
  //   axios
  //     .get('http://192.168.1.140:3023/workerFlow/workerids')
  //     .then((res) => {
  //       console.log('work flow list', res.data.result);
  //       setWorkflowdata(res.data.result);
  //     })
  //     .catch((err) => console.warn(err));
  // };
  // Utility function to convert configurations to JSON
  const convertToJSON = (type, inputArray) => {
    console.log('THe input array in conver to json in view', inputArray);
    if (inputArray == undefined) {
      return [];
    }

    if (type === 'notification') {
      // console.log(...inputArray[5].value.slice(1));
      // const result = [
      //   {
      //     channel: inputArray[0].value,
      //     emialId: inputArray[1].value[0],
      //     templateId: inputArray[2].value,
      //     tenantId: inputArray[3].value,
      //     message: inputArray[4].value,
      //     key: 'poAmount',
      //     value: inputArray[5].value[0].value
      //   },
      //   ...inputArray[5].value.slice(1)
      // ];

      const configData = {};
      for (let obj of inputArray) {
        if (obj.key === 'emailId') {
          configData[obj.key] = obj.value[0];
        } else if (obj.key === 'documentData') {
          configData.documentTypeValues = obj.value.map((item) => {
            return {
              key: item.uploadId,
              value: item.fileId
            };
          });

          delete obj.documentData;
        } else {
          configData[obj.key] = obj.value;
        }
      }

      console.log('configData', [configData]);
      return [configData];
    }

    if (type == 'iterativeFlow') {
      const result = {
        workerId: inputArray[0].value,
        version: inputArray[1].value,
        count: inputArray[2].value
      };
      return [result].flat();
    }

    if (type === 'http' || type === 'db') {
      const result = {};
      inputArray.forEach((pair) => {
        try {
          const parsedValue = JSON.parse(pair.value);
          result[pair.key] = parsedValue;
        } catch (e) {
          result[pair.key] = pair.value;
        }
      });
      return [result].flat();
    }
    if (type == 'wait') {
      if (inputArray[0].type == 'delay') {
        const result = {
          trigger: 'delay',
          type: inputArray[1].value,
          ms: inputArray[2].value
        };
        return [result].flat();
      } else {
        const result = {
          trigger: 'external',
          type: inputArray[1].value
        };
        return [result].flat();
      }
    }
    if (type == 'script') {
      // console.log(inputArray);
      const result = {
        functionDef: inputArray[0].value,
        functionCall: inputArray[1].value
      };
      return [result].flat();
    }
    if (type === 'sync') {
      let data = [];
      let findTypeIndex = inputArray.findIndex((item) => item.key === 'syncType');

      inputArray.forEach((item, index) => {
        if (index !== findTypeIndex) {
          data.push({
            sync: item.value,
            checkbox: inputArray[findTypeIndex].value == true ? 'all' : 'any'
          });
        }
      });
      // data.push({
      //   'checkbox':inputArray[findTypeIndex].value==true?'all':'any'
      // })
      // console.log(data);
      return data;
    } else {
      return inputArray.flat();
    }
  };

  // Function to set initial values based on the selected node
  const setPayloadInitialValues = (id, type) => {
    const filteredData = payload ? payload.filter((data) => data.id === id) : [];
    // console.log('Filtered Values', filteredData);
    if (filteredData.length > 0) {
      const data = filteredData[0];
      // console.log(data);
      const newInitialValues = {
        [id]: data.sequence || [],
        [`${id}+configurations`]: convertToJSON(type, data.configurations) || [],
        [`${id}+assign`]: data.assign || [],
        [`${id}+variables`]: data.variables || []
      };
      setInitialValues((prevValues) => ({ ...prevValues, ...newInitialValues }));
    }
  };

  useEffect(() => {
    if (selectedNodes?.id && selectedNodes?.data?.type) {
      setPayloadInitialValues(selectedNodes.id, selectedNodes.data.type);
    }
    // getworkflowList();
  }, [selectedNodes]);

  const [varKeys, setVarKeys] = useState([]);
  const fieldss = (id, version) => {
    // console.log('IDDDDDDDD', id);
    axios
      .get(`${process.env.REACT_APP_WORKFLOW}/workerFlow/subFlowId/${id}`)
      .then((res) => setVarKeys(res.data.primary));
    // .catch((err) => console.log(err));
  };

  const workFlowList = useSelector((state) => state.store.reactflow.workFlowList[0]);
  const handleDropdownChange = (event) => {
    // console.log('In handleDropdownChange');
    // console.log('eventtt', event.target.value);
    const selectedValue = event.target.value;
    // console.log('workFlowListtt', workFlowList);
    const selectedItem = workFlowList.find((item) => item.id === selectedValue);
    // console.log('selectedItem :- ', selectedItem);
    if (selectedItem) {
      fieldss(selectedItem.id, selectedItem.version);
    }
    // createSubflow();
  };
  const createHttpConfigData = (data) => {
    // console.log(data);
    let keys = Object.keys(data[0]);
    // console.log('keys', keys);
    // let values = Object.values(data);
    let Obj = [];
    for (let j = 0; j < keys.length; j++) {
      Obj.push({
        key: keys[j],
        value: data[0][keys[j]]
      });
    }
    // console.log(Obj);
    return Obj;
  };
  const createConfigdata = (data) => {
    // console.log(data);
    const obj = [];

    const gettype = data[0].splittype;
    data.map((config) => {
      if (gettype == 'withcondition') {
        obj.push({
          key: 'expression',
          val1: config.val1,
          val2: config.val2,
          op: config.op,
          value: config.value
        });
      } else {
        obj.push({
          key: 'split',
          value: config.nodeid,
          name: config.branch
        });
      }
    });

    obj.push({
      key: 'Splittype',
      value: gettype
    });

    // console.log('obj', obj);
    return obj;
  };
  const createwaitConfig = (data) => {
    // console.log(data);
    let keys = Object.keys(data[0]);
    // console.log('keys', keys);
    // let values = Object.values(data);
    let Obj = [];
    for (let j = 0; j < data.length; j++) {
      for (let i = 0; i < keys.length; i++) {
        Obj.push({
          key: keys[i],
          value: data[j][keys[i]]
        });
      }
    }
    // console.log(Obj);
    let datasss = [Obj];
    // console.log(datasss);
    const filteredValues = data.map((config) => {
      if (config.trigger === 'external') {
        return [
          {
            key: 'trigger',
            type: 'external'
          },
          {
            key: 'type',
            value: config.type
          }
        ];
      } else {
        return [
          {
            key: 'trigger',
            type: 'delay'
          },
          {
            key: 'type',
            value: config.type
          },
          {
            key: 'ms',
            value: config.ms
          }
        ];
      }
    });

    // Flatten the array of arrays into a single array
    const flattenedValues = filteredValues.flat();

    // Log or submit the flattened values
    // console.log('Filtered and Flattened Form Values:', flattenedValues);
    return flattenedValues;
  };
  const createSyncConfigdata = (data) => {
    // console.log(data);
    let keys = Object.keys(data[0]);
    // console.log('keys', keys);
    // let values = Object.values(data);
    let Obj = [];

    for (let j = 0; j < data.length; j++) {
      // console.log(data[j]['sync']);
      Obj.push({
        key: 'sync',
        value: data[j]['sync']
      });
    }
    Obj.push({
      key: 'syncType',
      value: data[0]['checkbox'] == true ? 'all' : 'any'
    });
    // console.log(Obj);
    return Obj;
  };
  const createDtData = (data) => {
    console.lo;
    let payload = [
      {
        key: 'type',
        type: data[0].type,
        value: ''
      },
      {
        key: 'write',
        type: 'data',
        value: ''
      },
      {
        key: 'read',
        type: 'data',
        value: data[0].value
      }
    ];
    return payload;
  };
  const createScriptConfigData = (data) => {
    let ans = [
      {
        key: 'type',
        type: 'data',
        value: data[0].functionDef
      },
      {
        key: 'function',
        value: data[0].functionCall
      }
    ];
    return ans;
  };
  const createIterativeData = (data) => {
    let payload = [
      {
        key: 'workerId',
        value: data[0].workerId,
        count: data[0].count
      }
    ];
    return payload;
  };
  const createSubflow = (data) => {
    let obj = [
      {
        key: 'workerId',
        value: data[0].workerid
      }
    ];
    // console.log('createSubflow data', obj);
    return obj;
  };
  const createsubflowVar = (data) => {
    let result = {};
    varKeys.forEach((key) => {
      if (data[0].hasOwnProperty(key)) {
        result[key] = data[0][key];
      }
    });
    // console.log('351111111111111111', result);
    return createHttpConfigData([result])[0];
  };
  const getConfig = (name, data) => {
    // console.log(name, data);
    if (data == undefined) {
      data = [];
      return data;
    }
    switch (name) {
      case 'start':
      case 'start':
        return data;
      case 'leaf' || 'terminate':
        return data;
      case 'split':
        return createConfigdata(data);
        return createConfigdata(data);
      case 'http':
        return createHttpConfigData(data);
        return createHttpConfigData(data);
      case 'script':
        return createScriptConfigData(data);
      case 'sync':
        return createSyncConfigdata(data);
      case 'subFlow':
        return createSubflow(data);
      case 'db':
        return createHttpConfigData(data);
      case 'dt':
        return createDtData(data);
      case 'iterativeFlow':
        return createIterativeData(data);
      case 'wait':
        return createwaitConfig(data);
      default:
        throw new Error('Invalid name');
    }
  };

  // Function to handle form submission
  const onSubmit = (id, data) => {
    let formdata = {};
    Object.keys(data).forEach((key) => {
      if (key === id) {
        formdata['sequence'] = data[key];
      } else if (key.startsWith(`${id}+`)) {
        const newKey = key.split('+')[1];
        formdata[newKey] = data[key];
      }
    });

    const dataObj = {
      id: selectedNodes.id,
      // name: selectedNodes.data.name,
      // type: selectedNodes.data.type,
      name: selectedNodes.data.data.type,
      type: selectedNodes.data.data.type,
      assign: formdata.assign || [],
      // variables: formdata.variables,
      variables:
        selectedNodes.data.data.type == 'subFlow'
          ? createsubflowVar(formdata.variables)
          : formdata.variables || [],
      // configurations:
      //   selectedNodes.data.type === 'start'
      //     ? formdata.configurations
      //     : getConfig(selectedNodes.data.type, formdata.configurations),
      configurations: getConfig(selectedNodes.data.data.type, formdata.configurations),
      sequence: formdata.sequence || [],
      position: selectedNodes.position
    };

    dispatch(pushEditFlowData(dataObj));
    alert('Data Has Been Saved!');
  };

  const submit = () => {
    let payload = formData.store.reactflow.editFlow;
    let versioning = formData.store.reactflow.versioning;
    // console.log('Meat Data', versioning);
    let data = {
      id: metaData[0].id,
      name: metaData[0].name,
      description: metaData[0].description,
      variables: metaData[0].variables,
      activities: payload,
      status: versioning[0].status,
      version: versioning[0].version
    };
    // console.log('Payload data is ', data, payload);

    // Uncomment below to make API call
    axios.post(`${process.env.REACT_APP_WORKFLOW}/workerFlow/createOrUpdate`, data).then((res) => {
      // console.log(res);
      alert('WorkFlow Has Been saved..👍');
    });
    // .catch((err) => console.log(err));
  };

  const [value, setValue] = React.useState('option1');

  const handleChange = (event) => {
    setValue(event.target.value);
    setEdit(event.target.value === 'edit');
  };
  console.log('Selected Nodes', selectedNodes.data.data.type);
  console.log('503 :- ', initialValues);
  function form() {
    switch (selectedNodes.data.data.type) {
      case 'leaf':
        return (
          <Box>
            <Form
              onSubmit={(values) => onSubmit(selectedNodes.id, values)}
              initialValues={initialValues}
              mutators={{
                ...arrayMutators
              }}
              keepDirtyOnReinitialize={true}
              render={({
                handleSubmit,
                form: {
                  mutators: { push, pop }
                },
                pristine,
                form,
                submitting,
                values
              }) => {
                return (
                  <form onSubmit={handleSubmit} key={selectedNodes.id}>
                    <Paper
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 4
                      }}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '24px', fontFamily: 'Roboto' }}>
                        End Node : {selectedNodes.id}
                      </Typography>

                      {/* <Button variant="contained" color="primary" type="submit" disabled={!edit}>
                        Save
                      </Button> */}

                      {/* <Button variant="contained" color="primary" type="submit" onClick={submit}>
                        Submit
                      </Button> */}
                    </Paper>

                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Sequence</p>
                    <FieldArray name={selectedNodes.id}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Result
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.result`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Result"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Node
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.node`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Node"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Edge Description
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.edgeDescription`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Node"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ result: '', node: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Configuration</p>

                    <FieldArray name={`${selectedNodes.id}+configurations`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key{index + 1}
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value{index + 1}
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ result: '', node: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Assign</p>
                    <FieldArray name={`${selectedNodes.id}+assign`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Variables</p>
                    <FieldArray name={`${selectedNodes.id}+variables`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </form>
                );
              }}
            />
          </Box>
        );
      case 'terminate':
        return (
          <Box>
            <Form
              onSubmit={(values) => onSubmit(selectedNodes.id, values)}
              initialValues={initialValues}
              mutators={{
                ...arrayMutators
              }}
              keepDirtyOnReinitialize={true}
              render={({
                handleSubmit,
                form: {
                  mutators: { push, pop }
                },
                pristine,
                form,
                submitting,
                values
              }) => {
                return (
                  <form onSubmit={handleSubmit} key={selectedNodes.id}>
                    <Paper
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 4
                      }}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '24px', fontFamily: 'Roboto' }}>
                        Terminated Node : {selectedNodes.id}
                      </Typography>

                      {/* <Button variant="contained" color="primary" type="submit" disabled={!edit}>
                        Save
                      </Button> */}

                      {/* <Button variant="contained" color="primary" type="submit" onClick={submit}>
                        Submit
                      </Button> */}
                    </Paper>

                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Sequence</p>
                    <FieldArray name={selectedNodes.id}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Result
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.result`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Result"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Node
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.node`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Node"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Edge Description
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.edgeDescription`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Node"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ result: '', node: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Configuration</p>

                    <FieldArray name={`${selectedNodes.id}+configurations`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key{index + 1}
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value{index + 1}
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ result: '', node: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Assign</p>
                    <FieldArray name={`${selectedNodes.id}+assign`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Variables</p>
                    <FieldArray name={`${selectedNodes.id}+variables`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </form>
                );
              }}
            />
          </Box>
        );
      case 'start':
        return (
          <Box>
            {/* <FormControl component="fieldset">
              <FormLabel component="legend">Select an option</FormLabel>
              <RadioGroup aria-label="options" name="options" value={value} onChange={handleChange}>
                <FormControlLabel value="edit" control={<Radio />} label="Edit" />
                <FormControlLabel value="Noedit" control={<Radio />} label="Non-Edit" />
              </RadioGroup>
            </FormControl> */}
            <Form
              onSubmit={(values) => onSubmit(selectedNodes.id, values)}
              mutators={{
                ...arrayMutators
              }}
              keepDirtyOnReinitialize={true}
              initialValues={initialValues}
              render={({
                handleSubmit,
                form: {
                  mutators: { push, pop }
                },
                pristine,
                form,
                submitting,
                values
              }) => {
                return (
                  <form onSubmit={handleSubmit} key={selectedNodes.id}>
                    <Paper
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 4
                      }}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '24px', fontFamily: 'Roboto' }}>
                        Start Node : {selectedNodes.id}
                      </Typography>

                      {/* <Button variant="contained" color="primary" type="submit" disabled={!edit}>
                        Save
                      </Button> */}
                    </Paper>

                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Sequence</p>
                    <FieldArray name={selectedNodes.id}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Result
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.result`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Result"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Node
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.node`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Node"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Edge Description
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.edgeDescription`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Node"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ result: '', node: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Configuration</p>

                    <FieldArray name={`${selectedNodes.id}+configurations`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key{index + 1}
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value{index + 1}
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Assign</p>
                    <FieldArray name={`${selectedNodes.id}+assign`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Variables</p>
                    <FieldArray name={`${selectedNodes.id}+variables`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </form>
                );
              }}
            />
          </Box>
        );
      case 'db':
        return (
          <Box>
            <Form
              initialValues={initialValues}
              onSubmit={(values) => onSubmit(selectedNodes.id, values)}
              mutators={{
                ...arrayMutators
              }}
              keepDirtyOnReinitialize={true}
              render={({
                handleSubmit,
                form: {
                  mutators: { push, pop }
                },
                pristine,
                form,
                submitting,
                values
              }) => {
                return (
                  <form onSubmit={handleSubmit} key={selectedNodes.id}>
                    <Paper
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 4
                      }}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '24px', fontFamily: 'Roboto' }}>
                        DataBase Node : {selectedNodes.id}
                      </Typography>
                      {/* <Button variant="contained" color="primary" type="submit" disabled={!edit}>
                        Save
                      </Button> */}
                    </Paper>

                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Sequence</p>
                    <FieldArray name={selectedNodes.id}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Result
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.result`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Result"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Node
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.node`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Node"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Edge Description
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.edgeDescription`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Node"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ result: '', node: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Configuration</p>

                    <FieldArray name={`${selectedNodes.id}+configurations`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Database Type
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.type`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Host
                                  </label>
                                  {!edit ? (
                                    <Typography
                                      style={{
                                        padding: 3,
                                        border: '2px solid blue',
                                        color: '#999'
                                      }}>
                                      $Host
                                    </Typography>
                                  ) : (
                                    <Field
                                      style={{
                                        padding: 3,
                                        border: '2px solid blue'
                                      }}
                                      name={`${name}.host`}
                                      component="input"
                                      disabled={!edit}
                                      placeholder="Value"
                                    />
                                  )}

                                  {/* {!edit ? (
                                    <Typography style={{ padding: 3, border: '2px solid blue', color: "#999" }}>$host</Typography>
                                  ) : (
                                    <Field
                                      style={{ padding: 3, border: '2px solid blue' }}
                                      name={`${name}.host`}
                                      component="input"
                                      disabled={!edit}
                                      placeholder="Host"
                                    />
                                  )} */}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Connection
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.connection`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    UserName
                                  </label>
                                  {/* <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.username`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  /> */}
                                  {!edit ? (
                                    <Typography
                                      style={{
                                        padding: 3,
                                        border: '2px solid blue',
                                        color: '#999'
                                      }}>
                                      $Username
                                    </Typography>
                                  ) : (
                                    <Field
                                      style={{ padding: 3, border: '2px solid blue' }}
                                      name={`${name}.username`}
                                      component="input"
                                      disabled={!edit}
                                      placeholder="User name"
                                    />
                                  )}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Password
                                  </label>
                                  {/* <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.password`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  /> */}
                                  {!edit ? (
                                    <Typography
                                      style={{
                                        padding: 3,
                                        border: '2px solid blue',
                                        color: '#999'
                                      }}>
                                      $password
                                    </Typography>
                                  ) : (
                                    <Field
                                      style={{ padding: 3, border: '2px solid blue' }}
                                      name={`${name}.password`}
                                      component="input"
                                      disabled={!edit}
                                      placeholder="password"
                                    />
                                  )}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    port
                                  </label>
                                  {!edit ? (
                                    <Typography
                                      style={{
                                        padding: 3,
                                        border: '2px solid blue',
                                        color: '#999'
                                      }}>
                                      $port
                                    </Typography>
                                  ) : (
                                    <Field
                                      style={{ padding: 3, border: '2px solid blue' }}
                                      name={`${name}.port`}
                                      component="input"
                                      disabled={!edit}
                                      placeholder="Value"
                                    />
                                  )}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Database
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.database`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Table
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.table`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Method
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.method`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    query
                                  </label>
                                  {/* <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.query`}
                                    component="textarea"
                                    disabled={!edit}
                                    placeholder="Value"
                                  /> */}
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.query`}
                                    component="textarea"
                                    disabled={!edit}
                                    placeholder="Value"
                                    parse={(v) => (edit ? JSON.parse(v) : JSON.stringify(v))} // Parse JSON string back to an array of objects when saving
                                    format={(v) =>
                                      Array.isArray(v)
                                        ? JSON.stringify(v, null, 2)
                                        : JSON.stringify(v)
                                    }
                                  />
                                </div>
                                {fields.value[index].method === 'update' && (
                                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label
                                      style={{
                                        fontSize: '16px',
                                        color: 'black',
                                        fontWeight: 'bold'
                                      }}>
                                      Filter
                                    </label>
                                    <Field
                                      style={{ padding: 3, border: '2px solid blue' }}
                                      name={`${name}.filter`}
                                      component="textarea"
                                      placeholder="Value"
                                      parse={(v) => JSON.stringify(v)}
                                      format={(v) =>
                                        Array.isArray(v)
                                          ? JSON.stringify(v, null, 2)
                                          : JSON.stringify(v)
                                      }
                                    />
                                  </div>
                                )}
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ result: '', node: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Assign</p>
                    <FieldArray name={`${selectedNodes.id}+assign`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Variables</p>
                    <FieldArray name={`${selectedNodes.id}+variables`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </form>
                );
              }}
            />
          </Box>
        );
      case 'customtransformation':
        return (
          <Box>
            <Form
              onSubmit={(values) => {
                onSubmit(selectedNodes.id, values);
                console.log('Values are...', values);
              }}
              mutators={{
                ...arrayMutators
              }}
              initialValues={initialValues}
              keepDirtyOnReinitialize={true}
              render={({
                handleSubmit,
                form: {
                  mutators: { push, pop }
                },
                pristine,
                form,
                submitting,
                values
              }) => {
                return (
                  <form onSubmit={handleSubmit} key={selectedNodes.id}>
                    <Paper
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 4
                      }}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '20px', fontFamily: 'Roboto' }}>
                        Custom Transformation : {selectedNodes.id}
                      </Typography>

                      {/* <Button variant="contained" color="primary" type="submit">
                        Save
                      </Button> */}
                    </Paper>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Sequence</p>
                    <FieldArray name={selectedNodes.id}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Result
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.result`}
                                    component="input"
                                    placeholder="Result"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Node
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.node`}
                                    component="input"
                                    placeholder="Node"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Edge Description
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.edgeDescription`}
                                    component="input"
                                    placeholder="Node"
                                  />
                                </div>
                                {/* <Button onClick={() => fields.remove(index)}>❌</Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() =>
                              fields.push({ result: '', node: '', edgeDescription: '' })
                            }>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Configuration</p>
                    <FieldArray name={`${selectedNodes.id}+configurations`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <Paper elevation={4}>
                                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label
                                      style={{
                                        fontSize: '16px',
                                        color: 'black',
                                        fontWeight: 'bold'
                                      }}>
                                      Key :
                                    </label>
                                    <Field
                                      style={{ padding: 3, border: '2px solid blue' }}
                                      name={`${name}.key`}
                                      component="input"
                                      placeholder="Value"
                                    />
                                  </div>
                                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label
                                      style={{
                                        fontSize: '16px',
                                        color: 'black',
                                        fontWeight: 'bold'
                                      }}>
                                      Name :
                                    </label>
                                    <Field
                                      style={{ padding: 3, border: '2px solid blue' }}
                                      name={`${name}.name`}
                                      component="input"
                                      placeholder="Value"
                                    />
                                  </div>
                                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label
                                      style={{
                                        fontSize: '16px',
                                        color: 'black',
                                        fontWeight: 'bold'
                                      }}>
                                      Value :
                                    </label>
                                    <Field
                                      style={{ padding: 3, border: '2px solid blue' }}
                                      name={`${name}.value`}
                                      component="input"
                                      placeholder="Value"
                                    />
                                  </div>

                                  {/* <Button onClick={() => fields.remove(index)}>❌</Button> */}
                                </Paper>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Assign</p>
                    <FieldArray name={`${selectedNodes.id}+assign`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button onClick={() => fields.remove(index)}>❌</Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Variables</p>
                    <FieldArray name={`${selectedNodes.id}+variables`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button onClick={() => fields.remove(index)}>❌</Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </form>
                );
              }}
            />
          </Box>
        );
      case 'notification':
        return (
          <Box>
            <Form
              onSubmit={(values) => {
                onSubmit(selectedNodes.id, values);
                console.log('Values are...', values);
              }}
              mutators={{
                ...arrayMutators
              }}
              keepDirtyOnReinitialize={true}
              initialValues={initialValues}
              render={({
                handleSubmit,
                form: {
                  mutators: { push, pop }
                },
                pristine,
                form,
                submitting,
                values
              }) => {
                console.log('4657 form and values are :-', form, values);
                console.log('initial values', initialValues);

                return (
                  <form onSubmit={handleSubmit} key={selectedNodes.id}>
                    <Paper
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 4
                      }}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '20px', fontFamily: 'Roboto' }}>
                        Notify Node : {selectedNodes.id}
                      </Typography>

                      {/* <Button variant="contained" color="primary" type="submit">
                      Save
                    </Button> */}
                    </Paper>

                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Sequence</p>
                    <FieldArray name={selectedNodes.id}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Result
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.result`}
                                    component="input"
                                    placeholder="Result"
                                    disabled={!edit}
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Node
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.node`}
                                    component="input"
                                    placeholder="Node"
                                    disabled={!edit}
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Edge Description
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.edgeDescription`}
                                    component="input"
                                    placeholder="Node"
                                    disabled={!edit}
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            disabled={!edit}
                            onClick={() =>
                              fields.push({ result: '', node: '', edgeDescription: '' })
                            }>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>

                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Configuration</p>
                    <FieldArray name={`${selectedNodes.id}+configurations`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 2 }}>
                                <div>
                                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label
                                      style={{
                                        fontSize: '16px',
                                        color: 'black',
                                        fontWeight: 'bold'
                                      }}>
                                      Channel
                                    </label>

                                    <label>
                                      <Field
                                        component="input"
                                        type="radio"
                                        name={`${name}.channel`}
                                        value="email"
                                        disabled={!edit}
                                      />
                                      Email
                                    </label>
                                    <label>
                                      <Field
                                        component="input"
                                        type="radio"
                                        name={`${name}.channel`}
                                        value="phone"
                                        disabled={!edit}
                                      />
                                      Phone Number
                                    </label>
                                  </div>

                                  {fields.value[0].channel == 'email' ? (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <label
                                        style={{
                                          fontSize: '16px',
                                          color: 'black',
                                          fontWeight: 'bold'
                                        }}>
                                        EmailId
                                      </label>
                                      <Field
                                        style={{ padding: 3, border: '2px solid blue' }}
                                        name={`${name}.emailId`}
                                        component="input"
                                        placeholder="Value"
                                        disabled={!edit}
                                      />
                                    </div>
                                  ) : (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <label
                                        style={{
                                          fontSize: '16px',
                                          color: 'black',
                                          fontWeight: 'bold'
                                        }}>
                                        Phone Number
                                      </label>
                                      <Field
                                        style={{ padding: 3, border: '2px solid blue' }}
                                        name={`${name}.mobileNumbers`}
                                        component="input"
                                        placeholder="Value"
                                        disabled={!edit}
                                      />
                                    </div>
                                  )}

                                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label
                                      style={{
                                        fontSize: '16px',
                                        color: 'black',
                                        fontWeight: 'bold'
                                      }}>
                                      Template ID
                                    </label>
                                    <Field
                                      style={{ padding: 3, border: '2px solid blue' }}
                                      name={`${name}.templateId`}
                                      component="input"
                                      placeholder="Value"
                                      disabled={!edit}
                                    />
                                  </div>
                                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label
                                      style={{
                                        fontSize: '16px',
                                        color: 'black',
                                        fontWeight: 'bold'
                                      }}>
                                      Tenant ID
                                    </label>
                                    <Field
                                      style={{ padding: 3, border: '2px solid blue' }}
                                      name={`${name}.tenantId`}
                                      component="input"
                                      placeholder="Value"
                                      disabled={!edit}
                                    />
                                  </div>
                                  {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label
                                      style={{
                                        fontSize: '16px',
                                        color: 'black',
                                        fontWeight: 'bold'
                                      }}>
                                      Message
                                    </label>
                                    <Field
                                      style={{ padding: 3, border: '2px solid blue' }}
                                      name={`${name}.message`}
                                      component="input"
                                      placeholder="Value"
                                      disabled={!edit}
                                    />
                                  </div> */}
                                </div>

                                <Paper
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    my: 2
                                  }}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      width: '100%',
                                      padding: '0.5rem 1rem'
                                    }}>
                                    <label
                                      style={{
                                        fontSize: '16px',
                                        color: 'black',
                                        fontWeight: 'bold'
                                      }}>
                                      Values
                                    </label>
                                    <Button
                                      disabled={!edit}
                                      variant="contained"
                                      color="primary"
                                      onClick={() => {
                                        const updatedValues = fields.value[index].values || [];
                                        updatedValues.push({ key: '', value: '' });
                                        fields.update(index, {
                                          ...fields.value[index],
                                          values: updatedValues
                                        });
                                      }}>
                                      <AddIcon />
                                    </Button>
                                  </div>

                                  {fields.value[index].values.length > 0
                                    ? fields?.value[index]?.values?.map(
                                        (keyValue, keyValueIndex) => (
                                          <Paper
                                            elevation={6}
                                            sx={{
                                              my: 0.5,
                                              p: 2,
                                              pt: 0,
                                              borderRadius: '5px',
                                              width: '93%'
                                            }}>
                                            <Button
                                              sx={{
                                                display: 'flex',
                                                justifySelf: 'right',
                                                my: 0.5,
                                                mr: -1.5,
                                                width: 2
                                              }}
                                              disabled={!edit}
                                              variant="contained"
                                              color="error"
                                              onClick={() => {
                                                const updatedValues = [
                                                  ...fields.value[index].values
                                                ];
                                                updatedValues.splice(keyValueIndex, 1);

                                                fields.update(index, {
                                                  ...fields.value[index],
                                                  values: updatedValues
                                                });
                                              }}>
                                              <CloseIcon />
                                            </Button>
                                            <div
                                              style={{ display: 'flex', flexDirection: 'column' }}>
                                              <label
                                                style={{
                                                  fontSize: '16px',
                                                  color: 'black',
                                                  fontWeight: 'bold'
                                                }}>
                                                Key
                                              </label>

                                              <Field
                                                style={{ padding: 3, border: '2px solid blue' }}
                                                name={`${name}.values[${keyValueIndex}].key`}
                                                component="input"
                                                placeholder="Key"
                                                disabled={!edit}
                                              />
                                            </div>
                                            <div
                                              style={{ display: 'flex', flexDirection: 'column' }}>
                                              <label
                                                style={{
                                                  fontSize: '16px',
                                                  color: 'black',
                                                  fontWeight: 'bold'
                                                }}>
                                                Value
                                              </label>
                                              <Field
                                                style={{ padding: 3, border: '2px solid blue' }}
                                                name={`${name}.values[${keyValueIndex}].value`}
                                                component="input"
                                                placeholder="Value"
                                                disabled={!edit}
                                              />
                                            </div>
                                          </Paper>
                                        )
                                      )
                                    : ''}
                                </Paper>

                                <Paper
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    my: 2
                                  }}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      width: '100%',
                                      padding: '0.5rem 1rem'
                                    }}>
                                    <label
                                      style={{
                                        fontSize: '16px',
                                        color: 'black',
                                        fontWeight: 'bold'
                                      }}>
                                      Document Type Values
                                    </label>
                                    <Button
                                      disabled={!edit}
                                      variant="contained"
                                      color="primary"
                                      onClick={() => {
                                        const updatedValues =
                                          fields.value[index].documentTypeValues || [];
                                        updatedValues.push({ key: '', value: '' });
                                        fields.update(index, {
                                          ...fields.value[index],
                                          documentTypeValues: updatedValues
                                        });
                                      }}>
                                      <AddIcon />
                                    </Button>
                                  </div>
                                  {console.log('fields.value', fields.value)}
                                  {fields?.value[index]?.documentTypeValues?.map(
                                    (keyValue, docIndex) => (
                                      <Paper
                                        elevation={6}
                                        sx={{
                                          my: 0.5,
                                          p: 2,
                                          pt: 0,
                                          borderRadius: '5px',
                                          width: '93%'
                                        }}>
                                        <Button
                                          sx={{
                                            display: 'flex',
                                            justifySelf: 'right',
                                            my: 0.5,
                                            mr: -1.5,
                                            width: 2
                                          }}
                                          disabled={!edit}
                                          variant="contained"
                                          color="error"
                                          onClick={() => {
                                            const updatedDocumentTypeValues = [
                                              ...fields.value[index].documentTypeValues
                                            ];
                                            updatedDocumentTypeValues.splice(docIndex, 1);

                                            fields.update(index, {
                                              ...fields.value[index],
                                              documentTypeValues: updatedDocumentTypeValues
                                            });
                                          }}>
                                          <CloseIcon />
                                        </Button>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                          <label
                                            style={{
                                              fontSize: '16px',
                                              color: 'black',
                                              fontWeight: 'bold'
                                            }}>
                                            Key
                                          </label>
                                          <Field
                                            style={{ padding: 3, border: '2px solid blue' }}
                                            name={`${name}.documentTypeValues[${docIndex}].key`}
                                            component="input"
                                            placeholder="Key"
                                            disabled={!edit}
                                          />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                          <label
                                            style={{
                                              fontSize: '16px',
                                              color: 'black',
                                              fontWeight: 'bold'
                                            }}>
                                            Value
                                          </label>
                                          <Field
                                            style={{ padding: 3, border: '2px solid blue' }}
                                            name={`${name}.documentTypeValues[${docIndex}].value`}
                                            component="input"
                                            placeholder="Value"
                                            disabled={!edit}
                                          />
                                        </div>
                                      </Paper>
                                    )
                                  )}
                                </Paper>

                                <Button
                                  variant="outlined"
                                  color="error"
                                  disabled={!edit}
                                  onClick={() => fields.remove(index)}>
                                  Delete
                                </Button>
                              </Paper>
                            </div>
                          ))}
                          <Button
                            disabled={!edit}
                            variant="contained"
                            color="success"
                            onClick={() => {
                              if (fields.length < 1) {
                                fields.push({ key: '', value: '' });
                              }
                            }}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>

                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Assign</p>
                    <FieldArray name={`${selectedNodes.id}+assign`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    placeholder="Key"
                                    disabled={!edit}
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                    disabled={!edit}
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            disabled={!edit}
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>

                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Variables</p>
                    <FieldArray name={`${selectedNodes.id}+variables`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    placeholder="Key"
                                    disabled={!edit}
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                    disabled={!edit}
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            disabled={!edit}
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </form>
                );
              }}
            />
          </Box>
        );
      case 'split':
        return (
          <Box>
            <Form
              onSubmit={(values) => onSubmit(selectedNodes.id, values)}
              initialValues={initialValues}
              mutators={{
                ...arrayMutators
              }}
              keepDirtyOnReinitialize={true}
              render={({
                handleSubmit,
                form: {
                  mutators: { push, pop }
                },
                pristine,
                form,
                submitting,
                values
              }) => {
                return (
                  <form onSubmit={handleSubmit} key={selectedNodes.id}>
                    <Paper
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 4
                      }}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '24px', fontFamily: 'Roboto' }}>
                        Split Node : {selectedNodes.id}
                      </Typography>

                      {/* <Button variant="contained" color="primary" type="submit" disabled={!edit}>
                        Save
                      </Button> */}
                    </Paper>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Sequence</p>
                    <FieldArray name={selectedNodes.id}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Result
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.result`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Result"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Node
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.node`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Node"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Edge Description
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.edgeDescription`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Node"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ result: '', node: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Configuration</p>

                    <FieldArray name={`${selectedNodes.id}+configurations`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                {index === 0 && (
                                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label>
                                      <Field
                                        name={`${name}.splittype`}
                                        component="input"
                                        type="radio"
                                        value="withcondition"
                                      />
                                      With Condition
                                    </label>
                                    <label>
                                      <Field
                                        name={`${name}.splittype`}
                                        component="input"
                                        type="radio"
                                        value="withoutcondition"
                                      />
                                      Without Condition
                                    </label>
                                  </div>
                                )}
                                {fields.value[0].splittype == 'withoutcondition' ? (
                                  <>
                                    <div>
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label
                                          style={{
                                            fontSize: '16px',
                                            color: 'black',
                                            fontWeight: 'bold'
                                          }}>
                                          Split
                                        </label>
                                        <Field
                                          style={{ padding: 3, border: '2px solid blue' }}
                                          name={`${name}.split`}
                                          defaultValue="split"
                                          component="input"
                                          disabled
                                        />
                                      </div>
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label
                                          style={{
                                            fontSize: '16px',
                                            color: 'black',
                                            fontWeight: 'bold'
                                          }}>
                                          NodeID
                                        </label>
                                        <Field
                                          style={{ padding: 3, border: '2px solid blue' }}
                                          name={`${name}.nodeid`}
                                          component="input"
                                          placeholder="Value"
                                        />
                                      </div>
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label
                                          style={{
                                            fontSize: '16px',
                                            color: 'black',
                                            fontWeight: 'bold'
                                          }}>
                                          BranchName
                                        </label>
                                        <Field
                                          style={{ padding: 3, border: '2px solid blue' }}
                                          name={`${name}.branch`}
                                          component="input"
                                          placeholder="Value"
                                        />
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <label
                                        style={{
                                          fontSize: '16px',
                                          color: 'black',
                                          fontWeight: 'bold'
                                        }}>
                                        Key
                                      </label>
                                      <Field
                                        style={{ padding: 3, border: '2px solid blue' }}
                                        name={`${name}.key`}
                                        defaultValue="expression"
                                        component="input"
                                        disabled
                                      />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <label
                                        style={{
                                          fontSize: '16px',
                                          color: 'black',
                                          fontWeight: 'bold'
                                        }}>
                                        Value 1
                                      </label>
                                      <Field
                                        style={{ padding: 3, border: '2px solid blue' }}
                                        name={`${name}.val1`}
                                        component="input"
                                        placeholder="Value"
                                      />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <label
                                        style={{
                                          fontSize: '16px',
                                          color: 'black',
                                          fontWeight: 'bold'
                                        }}>
                                        Value 2
                                      </label>
                                      <Field
                                        style={{ padding: 3, border: '2px solid blue' }}
                                        name={`${name}.val2`}
                                        component="input"
                                        placeholder="Value 2"
                                      />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <label
                                        style={{
                                          fontSize: '16px',
                                          color: 'black',
                                          fontWeight: 'bold'
                                        }}>
                                        Conditional Operator
                                      </label>
                                      <Field
                                        style={{ padding: 3, border: '2px solid blue' }}
                                        name={`${name}.op`}
                                        component="input"
                                        placeholder="Value"
                                      />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <label
                                        style={{
                                          fontSize: '16px',
                                          color: 'black',
                                          fontWeight: 'bold'
                                        }}>
                                        Node ID
                                      </label>
                                      <Field
                                        style={{ padding: 3, border: '2px solid blue' }}
                                        name={`${name}.value`}
                                        component="input"
                                        placeholder="Value"
                                      />
                                    </div>
                                  </>
                                )}

                                {/* <Button onClick={() => fields.remove(index)}>❌</Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ splittype: 'withoutcondition' })} // Initialize with default value
                          >
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Assign</p>
                    <FieldArray name={`${selectedNodes.id}+assign`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Variables</p>
                    <FieldArray name={`${selectedNodes.id}+variables`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </form>
                );
              }}
            />
          </Box>
        );
      case 'sync':
        return (
          <Box>
            <Form
              onSubmit={(values) => onSubmit(selectedNodes.id, values)}
              initialValues={initialValues}
              mutators={{
                ...arrayMutators
              }}
              keepDirtyOnReinitialize={true}
              render={({
                handleSubmit,
                form: {
                  mutators: { push, pop }
                },
                pristine,
                form,
                submitting,
                values
              }) => {
                return (
                  <form onSubmit={handleSubmit} key={selectedNodes.id}>
                    <Paper
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 4
                      }}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '24px', fontFamily: 'Roboto' }}>
                        Sync Node : {selectedNodes.id}
                      </Typography>

                      {/* <Button variant="contained" color="primary" type="submit" disabled={!edit}>
                        Save
                      </Button> */}
                    </Paper>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Sequence</p>
                    <FieldArray name={selectedNodes.id}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Result
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.result`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Result"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Node
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.node`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Node"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Edge Description
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.edgeDescription`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Node"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ result: '', node: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Configuration</p>

                    <FieldArray name={`${selectedNodes.id}+configurations`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {index === 0 && (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      marginRight: 5
                                    }}>
                                    <label
                                      style={{
                                        fontSize: '16px',
                                        color: 'black',
                                        fontWeight: 'bold'
                                      }}>
                                      Select All
                                    </label>
                                    <Field
                                      style={{ padding: 3, border: '2px solid blue' }}
                                      name={`${name}.checkbox`}
                                      type="checkbox"
                                      component="input"
                                    />
                                  </div>
                                )}
                                <label
                                  style={{
                                    fontSize: '16px',
                                    color: 'black',
                                    fontWeight: 'bold'
                                  }}>
                                  Synchronized Node ID
                                </label>
                                <Field
                                  style={{ padding: 3, border: '2px solid blue' }}
                                  name={`${name}.sync`}
                                  component="input"
                                  placeholder="Value"
                                />

                                <button type="button" onClick={() => fields.remove(index)}>
                                  ❌
                                </button>
                              </div>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => {
                              fields.push({ sync: '', checkbox: fields.length === 0 });
                            }}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>

                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Assign</p>
                    <FieldArray name={`${selectedNodes.id}+assign`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Variables</p>
                    <FieldArray name={`${selectedNodes.id}+variables`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </form>
                );
              }}
            />
          </Box>
        );
      case 'script':
        return (
          <Box>
            <Form
              onSubmit={(values) => onSubmit(selectedNodes.id, values)}
              initialValues={initialValues}
              mutators={{
                ...arrayMutators
              }}
              keepDirtyOnReinitialize={true}
              render={({
                handleSubmit,
                form: {
                  mutators: { push, pop }
                },
                pristine,
                form,
                submitting,
                values
              }) => {
                return (
                  <form onSubmit={handleSubmit} key={selectedNodes.id}>
                    <Paper
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 4
                      }}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '24px', fontFamily: 'Roboto' }}>
                        Script Node : {selectedNodes.id}
                      </Typography>

                      {/* <Button variant="contained" color="primary" type="submit" disabled={!edit}>
                        Save
                      </Button> */}
                    </Paper>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Sequence</p>
                    <FieldArray name={selectedNodes.id}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Result
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.result`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Result"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Node
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.node`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Node"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Edge Description
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.edgeDescription`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Node"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ result: '', node: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Configuration</p>

                    <FieldArray name={`${selectedNodes.id}+configurations`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Function Defination
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.functionDef`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Function Call
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.functionCall`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>

                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ functionDef: '', functionCall: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Assign</p>
                    <FieldArray name={`${selectedNodes.id}+assign`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Variables</p>
                    <FieldArray name={`${selectedNodes.id}+variables`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </form>
                );
              }}
            />
          </Box>
        );
      case 'wait':
        return (
          <Box>
            <Form
              onSubmit={(values) => onSubmit(selectedNodes.id, values)}
              initialValues={initialValues}
              mutators={{
                ...arrayMutators
              }}
              keepDirtyOnReinitialize={true}
              render={({
                handleSubmit,
                form: {
                  mutators: { push, pop }
                },
                pristine,
                form,
                submitting,
                values
              }) => {
                return (
                  <form onSubmit={handleSubmit} key={selectedNodes.id}>
                    <Paper
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 4
                      }}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '24px', fontFamily: 'Roboto' }}>
                        Wait Node : {selectedNodes.id}
                      </Typography>

                      {/* <Button variant="contained" color="primary" type="submit" disabled={!edit}>
                        Save
                      </Button> */}
                    </Paper>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Sequence</p>
                    <FieldArray name={selectedNodes.id}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Result
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.result`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Result"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Node
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.node`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Node"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Edge Description
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.edgeDescription`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Node"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ result: '', node: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Configuration</p>

                    <FieldArray name={`${selectedNodes.id}+configurations`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div
                              key={name}
                              style={{
                                border: '1px solid #ddd',
                                padding: '15px',
                                marginBottom: '10px'
                              }}>
                              {/* <div style={{ marginBottom: '10px' }}>
                                <label htmlFor={`config-first-dropdown-${index}`}>Type</label>
                                <Field
                                  name={`${name}.trigger`}
                                  component="select"
                                  id={`config-first-dropdown-${index}`}
                                  // onChange={(event) => handleConfigSelectionChange(index, event)}
                                  // value={configSelections[index]?.type || ''}
                                >
                                  <option value="">Select type</option>
                                  <option value="external">External</option>
                                  <option value="delay">Delay</option>
                                </Field>
                              </div> */}
                              
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <label
                                  style={{
                                    fontSize: '16px',
                                    color: 'black',
                                    fontWeight: 'bold'
                                  }}>
                                  Trigger
                                </label>
                                <Field
                                  style={{ padding: 3, border: '2px solid blue' }}
                                  name={`${name}.trigger`}
                                  component="input"
                                  disabled={!edit}
                                  placeholder="Trigger"
                                />
                              </div>
                              {/* Conditionally render fields based on the trigger value */}
                              {fields.value[index]?.trigger === 'external' && (
                                // <Field name={`${name}.type`} component="select">
                                //   <option value="">Select option</option>
                                //   <option value="http">HTTP</option>
                                //   <option value="kafka">Kafka</option>
                                // </Field>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Trigger Type
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.type`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Trigger Type"
                                  />
                                </div>
                              )}

                              {fields.value[index]?.trigger === 'delay' && (
                                <>
                                  {/* <Field name={`${name}.type`} component="select">
                                    <option value="">Select option</option>
                                    <option value="time">Time</option>
                                    <option value="timeout">Timeout</option>
                                  </Field>
                                  <div style={{ marginBottom: '10px' }}>
                                    <label htmlFor={`milliseconds-${index}`}>Milliseconds</label>
                                    <Field
                                      name={`${name}.ms`}
                                      component="input"
                                      type="number"
                                      placeholder="Milliseconds"
                                      style={{ padding: '5px', width: '100%' }}
                                      id={`milliseconds-${index}`}
                                    />
                                  </div> */}

                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Trigger Type
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.type`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Trigger Type"
                                  />
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Milliseconds
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.ms`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Milliseconds"
                                  />
                                </div>
                                </>
                              )}

                              {/* <button type="button" onClick={() => fields.remove(index)}>
                                ❌ Remove
                              </button> */}
                            </div>
                          ))}
                          <Button
                            // disabled={fields.value.length}
                            disabled={!edit}
                            variant="contained"
                            color="success"
                            onClick={() => {
                              // const newIndex = fields.length; // Determine the new index
                              fields.push({ trigger: '', type: '', ms: '' });
                            }}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Assign</p>
                    <FieldArray name={`${selectedNodes.id}+assign`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Variables</p>
                    <FieldArray name={`${selectedNodes.id}+variables`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </form>
                );
              }}
            />
          </Box>
        );
      case 'http':
        return (
          <Box>
            <Form
              initialValues={initialValues}
              onSubmit={(values) => onSubmit(selectedNodes.id, values)}
              mutators={{
                ...arrayMutators
              }}
              keepDirtyOnReinitialize={true}
              render={({
                handleSubmit,
                form: {
                  mutators: { push, pop }
                },
                pristine,
                form,
                submitting,
                values
              }) => {
                return (
                  <form onSubmit={handleSubmit} key={selectedNodes.id}>
                    <Paper
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 4
                      }}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '24px', fontFamily: 'Roboto' }}>
                        HTTP Node : {selectedNodes.id}
                      </Typography>

                      {/* <Button variant="contained" color="primary" type="submit" disabled={!edit}>
                        Save
                      </Button> */}
                    </Paper>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Sequence</p>
                    <FieldArray name={selectedNodes.id}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Result
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.result`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Result"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Node
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.node`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Node"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Edge Description
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.edgeDescription`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Node"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ result: '', node: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Configuration</p>

                    <FieldArray name={`${selectedNodes.id}+configurations`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Method
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.method`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Header
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.header`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Endpoint
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.url`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Body
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.body`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                          disabled={!edit}
                            variant="contained"
                            color="success"
                            onClick={() =>
                              fields.push({ method: '', header: '', endpoint: '', body: '' })
                            }>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Assign</p>
                    <FieldArray name={`${selectedNodes.id}+assign`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Variables</p>
                    <FieldArray name={`${selectedNodes.id}+variables`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </form>
                );
              }}
            />
          </Box>
        );
      case 'subFlow':
        return (
          <Box>
            <Form
              onSubmit={(values) => onSubmit(selectedNodes.id, values)}
              initialValues={initialValues}
              mutators={{
                ...arrayMutators
              }}
              keepDirtyOnReinitialize={true}
              render={({
                handleSubmit,
                form: {
                  mutators: { push, pop }
                },
                pristine,
                form,
                submitting,
                values
              }) => {
                return (
                  <form onSubmit={handleSubmit} key={selectedNodes.id}>
                    <Paper
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 4
                      }}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '20px', fontFamily: 'Roboto' }}>
                        SubFlow : {selectedNodes.id}
                      </Typography>

                      {/* <Button variant="contained" color="primary" type="submit" disabled={!edit}>
                        Save
                      </Button> */}
                    </Paper>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Sequence</p>
                    <FieldArray name={selectedNodes.id}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Result
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.result`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Result"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Node
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.node`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Node"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Edge Description
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.edgeDescription`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Node"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ result: '', node: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Configuration</p>

                    <FieldArray name={`${selectedNodes.id}+configurations`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    WorkerID
                                  </label>
                                  <Field name={`${name}.workerid`}>
                                    {({ input }) => (
                                      <select
                                        {...input}
                                        style={{
                                          padding: '5px',
                                          border: '2px solid blue',
                                          borderRadius: '5px',
                                          marginLeft: '10px'
                                        }}
                                        onChange={(event) => {
                                          input.onChange(event); // Call react-final-form's onChange
                                          handleDropdownChange(event);
                                        }}>
                                        <option value="" disabled>
                                          Select Workflow
                                        </option>
                                        {workFlowList.map((item, index) => (
                                          <option key={index} value={item.id}>
                                            {item.show}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                  </Field>
                                </div>
                                {/* <Button onClick={() => fields.remove(index)}>❌</Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => fields.push({ workerid: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Assign</p>
                    <FieldArray name={`${selectedNodes.id}+assign`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Variables</p>
                    <FieldArray name={`${selectedNodes.id}+variables`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                {varKeys?.map((data, index) => (
                                  <div
                                    style={{ display: 'flex', flexDirection: 'column' }}
                                    key={index}>
                                    <label
                                      style={{
                                        fontSize: '16px',
                                        color: 'black',
                                        fontWeight: 'bold'
                                      }}>
                                      {data}
                                    </label>
                                    <Field
                                      style={{ padding: 3, border: '2px solid blue' }}
                                      name={`${name}.${data}`}
                                      component="input"
                                      placeholder="Key"
                                    />
                                  </div>
                                ))}

                                {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    placeholder="Value"
                                  />
                                </div> */}
                                {/* <Button
                                  onClick={() => {
                                    fields.remove(index);
                                  }}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            // disabled={workerid.length==0}
                            onClick={() => {
                              fields.push({});
                              // fieldss(workerid);
                            }}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </form>
                );
              }}
            />
          </Box>
        );
      case 'dt':
        return (
          <Box>
            <Form
              onSubmit={(values) => onSubmit(selectedNodes.id, values)}
              initialValues={initialValues}
              mutators={{
                ...arrayMutators
              }}
              keepDirtyOnReinitialize={true}
              render={({
                handleSubmit,
                form: {
                  mutators: { push, pop }
                },
                pristine,
                form,
                submitting,
                values
              }) => {
                return (
                  <form onSubmit={handleSubmit} key={selectedNodes.id}>
                    <Paper
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 4
                      }}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '24px', fontFamily: 'Roboto' }}>
                        Data Transform : {selectedNodes.id}
                      </Typography>

                      {/* <Button variant="contained" color="primary" type="submit" disabled={!edit}>
                        Save
                      </Button> */}
                    </Paper>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Sequence</p>
                    <FieldArray name={selectedNodes.id}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Result
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.result`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Result"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Node
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.node`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Node"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Edge Description
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.edgeDescription`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Node"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ result: '', node: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Configuration</p>

                    <FieldArray name={`${selectedNodes.id}+configurations`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <h4
                                  style={{
                                    fontSize: '16px',
                                    color: 'black',
                                    fontWeight: 'bold'
                                  }}>
                                  Configuration{' '}
                                </h4>

                                {/* Radio Buttons for xmltojson and jsontoxml */}
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label>
                                    <Field
                                      name={`${name}.conversionType`}
                                      component="input"
                                      disabled={!edit}
                                      type="radio"
                                      value="xmltojson"
                                    />
                                    XML to JSON
                                  </label>
                                  <label>
                                    <Field
                                      name={`${name}.conversionType`}
                                      component="input"
                                      disabled={!edit}
                                      type="radio"
                                      value="jsontoxml"
                                    />
                                    JSON to XML
                                  </label>
                                </div>

                                {/* Text Field with Default Value */}
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Write
                                  </label>
                                  <Field
                                    name={`${name}.write`}
                                    component="input"
                                    disabled={!edit}
                                    type="text"
                                    defaultValue="data"
                                    style={{ padding: 3, border: '2px solid blue' }}
                                  />
                                </div>

                                {/* Text Field with Specific Value */}
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Type
                                  </label>
                                  <Field
                                    name={`${name}.type`}
                                    component="input"
                                    disabled={!edit}
                                    type="text"
                                    value="nag" // fixed value, this won't change unless manually set
                                    readOnly
                                    style={{ padding: 3, border: '2px solid blue' }}
                                  />
                                </div>

                                {/* General Text Field */}
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value
                                  </label>
                                  <Field
                                    name={`${name}.value`}
                                    component="input"
                                    disabled={!edit}
                                    type="text"
                                    placeholder="Enter a value"
                                    style={{ padding: 3, border: '2px solid blue' }}
                                  />
                                </div>

                                {/* Remove Button */}
                                {/* <Button
                                  variant="contained"
                                  color="error"
                                  onClick={() => fields.remove(index)}
                                  style={{ marginTop: '10px' }}>
                                  ❌ Remove
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() =>
                              fields.push({
                                conversionType: '',
                                write: 'data',
                                type: 'nag',
                                value: ''
                              })
                            }
                            style={{ marginTop: '10px', padding: '5px 10px' }}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>

                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Assign</p>
                    <FieldArray name={`${selectedNodes.id}+assign`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Variables</p>
                    <FieldArray name={`${selectedNodes.id}+variables`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </form>
                );
              }}
            />
          </Box>
        );
      case 'iterativeFlow':
        return (
          <Box>
            <Form
              onSubmit={(values) => onSubmit(selectedNodes.id, values)}
              initialValues={initialValues}
              mutators={{
                ...arrayMutators
              }}
              keepDirtyOnReinitialize={true}
              render={({
                handleSubmit,
                form: {
                  mutators: { push, pop }
                },
                pristine,
                form,
                submitting,
                values
              }) => {
                return (
                  <form onSubmit={handleSubmit} key={selectedNodes.id}>
                    <Paper
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 4
                      }}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '20px', fontFamily: 'Roboto' }}>
                        Iteration Node : {selectedNodes.id}
                      </Typography>

                      {/* <Button variant="contained" color="primary" type="submit" disabled={!edit}>
                        Save
                      </Button> */}
                    </Paper>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Sequence</p>
                    <FieldArray name={selectedNodes.id}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Result
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.result`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Result"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Node
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.node`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Node"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Edge Description
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.edgeDescription`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Node"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() =>
                              fields.push({ result: '', node: '', edgeDescription: '' })
                            }>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Configuration</p>

                    <FieldArray name={`${selectedNodes.id}+configurations`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    WorkerID
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.workerId`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Vesrion
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.version`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Count
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.count`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ workerid: '', count: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Assign</p>
                    <FieldArray name={`${selectedNodes.id}+assign`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Variables</p>
                    <FieldArray name={`${selectedNodes.id}+variables`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </form>
                );
              }}
            />
          </Box>
        );
      default:
        return (
          <Box>
            <Form
              onSubmit={(values) => onSubmit(selectedNodes.id, values)}
              initialValues={initialValues}
              mutators={{
                ...arrayMutators
              }}
              keepDirtyOnReinitialize={true}
              render={({
                handleSubmit,
                form: {
                  mutators: { push, pop }
                },
                pristine,
                form,
                submitting,
                values
              }) => {
                return (
                  <form onSubmit={handleSubmit} key={selectedNodes.id}>
                    <Paper
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 4
                      }}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '20px', fontFamily: 'Roboto' }}>
                        Conditional Operator : {selectedNodes.id}
                      </Typography>
                      {/* <Button variant="contained" color="primary" type="submit" disabled={!edit}>
                        Save
                      </Button> */}
                    </Paper>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Sequence</p>
                    <FieldArray name={selectedNodes.id}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Result
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.result`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Result"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Node
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.node`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Node"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Edge Description
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.edgeDescription`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Node"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ result: '', node: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Configuration</p>

                    <FieldArray name={`${selectedNodes.id}+configurations`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    value="expression"
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    value 1
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.val1`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value 2
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.val2`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value 2"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Conditional Operator
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.op`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Node ID
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() =>
                              fields.push({ key: '', op: '', value: '', val1: '', val2: '' })
                            }>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Assign</p>
                    <FieldArray name={`${selectedNodes.id}+assign`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Variables</p>
                    <FieldArray name={`${selectedNodes.id}+variables`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <Paper sx={{ p: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Key
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.key`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Key"
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label
                                    style={{
                                      fontSize: '16px',
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>
                                    Value
                                  </label>
                                  <Field
                                    style={{ padding: 3, border: '2px solid blue' }}
                                    name={`${name}.value`}
                                    component="input"
                                    disabled={!edit}
                                    placeholder="Value"
                                  />
                                </div>
                                {/* <Button disabled={!edit} onClick={() => fields.remove(index)}>
                                  ❌
                                </Button> */}
                              </Paper>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            disabled={!edit}
                            color="success"
                            onClick={() => fields.push({ key: '', value: '' })}>
                            <AddIcon />
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </form>
                );
              }}
            />
          </Box>
        );
    }
  }

  return (
    <div key={selectedNodes.id}>
      <FormCreate>{form()}</FormCreate>
    </div>
  );
});

export default ViewFormSection;
