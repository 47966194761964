import React, { useEffect, useState } from 'react';
import TraceForm from './traceForm.component';
import TraceGrid from './traceGrid.component';
import keycloak from '../../../Keycloak';
import request from 'superagent';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, TextField } from '@mui/material';
import GenericModalContainer from '../../modals/genericModal/container';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import CriteriaGrid from './criteriaGrid.component';
import CloseIcon from '@mui/icons-material/Close';
import ConformationContainer from '../../common/conformationModal/containers';
import { noop } from 'lodash';
import { Label } from '@mui/icons-material';
import { toast } from 'react-hot-toast';
import Success from '../../notify/Success';
import HorizontalBarChart from './parentChart.component';
import moment from 'moment';

const TraceComponent = () => {
  const dispatch = useDispatch();
  const [traceData, setTraceData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const { app_code } = useParams();
  const [trigger, setTrigger] = useState(true);
  const [gridTrigger, setGridTrigger ] = useState(true)

  const [criteriaName, setCriteriaName] = useState();
  const [criteriaFieldName, setCriteriaFieldName] = useState();

  const [data_field,setDataField] = useState();
  const [data_value,setDataValue] = useState();

  const formStore = useSelector((store) => store?.store?.form?.formData) || {};

  const traceStore = formStore['Trace']?.['Trace-0'] || {};

  const fromDate = traceStore['From Date'] || moment().format("ddd MMM D YYYY HH:mm:ss [GMT]ZZ");
  const toDate = traceStore['To Date'] || moment().format("ddd MMM D YYYY HH:mm:ss [GMT]ZZ");
  const searchBy = traceStore['Search By'];


  function convertDateFormat(inputDate) {
    const date = new Date(inputDate);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
  }

  // const fetchTraceData = async ({ pageNo = 1, size = 10 , searchByValue} = {}) => {
  const fetchTraceData = async ({ pageNo = 1, size = 10 } = {}) => {

    const response = await request
      .post(`${process.env.REACT_APP_GENRIC_API}/generic/feature/trace/getAllTraces`)
      .send({
        search_type: searchBy ?? searchByValue,
        payload: {
          start_date: convertDateFormat(fromDate),
          end_date: convertDateFormat(toDate),
          app_code: app_code,
          pageNo: pageNo,
          size: size,
          data_value: data_value,
          data_field: data_field
        }
      })
      .set('Authorization', `Bearer ${keycloak.token()}`);
    
    console.log("75 :- ",response.body);
    if (response.body.status == 'success') {
      const result = response.body.response;
      setTraceData(result);
    }

    return response;
  };

  const onFormSubmit = () => {
    setGridTrigger(!gridTrigger)
    fetchTraceData();
  };

  const onCriteriaButtonClick = () => {
    dispatch(
      genericModalStateUpdate({
        modalKey: ModalKeys.criteriaModal,
        isOpen: true
      })
    );
  };

  const addCriteriaOnClick = () => {
    dispatch(
      genericModalStateUpdate({
        modalKey: ModalKeys.addCriteria,
        isOpen: true
      })
    );
  };

  const customComponent = (
    <div>
      <div className="mb-2">
        <div>Criteria Name :</div>
        <TextField
          value={criteriaName}
          onChange={(e) => setCriteriaName(e.target.value)}></TextField>
      </div>
      <div>
        <div>Criteria Field Name :</div>
        <TextField
          value={criteriaFieldName}
          onChange={(e) => setCriteriaFieldName(e.target.value)}></TextField>
      </div>
    </div>
  );

  const onConformModalSubmit = async () => {
    const response = await request
      .post(`${process.env.REACT_APP_GENRIC_API}/generic/feature/criteria/create`)
      .send({
        criteria: {
          dataField: criteriaFieldName,
          name: criteriaName
        }
      })
      .set('Authorization', `Bearer ${keycloak.token()}`);

    if (response?.body?.response?.status?.acknowledged) {
      setTrigger(!trigger);
      setCriteriaFieldName('');
      setCriteriaName('');
      toast.custom((t) => <Success t={t} message={'Added Criteria SuccessFully'} />, {
        duration: 4000
      });
    }
  };

  const onParentTraceGridClick = async (parentTraceId) => {
    const responseData = await request
      .post(`${process.env.REACT_APP_GENRIC_API}/generic/feature/trace/getTraces`)
      .send({
        parent_trace_id: parentTraceId
      })
      .set('Authorization', `Bearer ${keycloak.token()}`);
    setChartData({
      data: responseData.body.response,
      parentTraceId: parentTraceId
    });
    dispatch(
      genericModalStateUpdate({
        modalKey: ModalKeys.horizontalChart,
        isOpen: true
      })
    );
  };

  return (
    <div className="flex-1 flex flex-col w-[100%] bg-[#F4F3FA]">
      <div className="ml-4 mt-4">
        {/* <Button
          onClick={onCriteriaButtonClick}
          className="!bg-white !text-black !normal-case"
          variant="contained">
          Criteria List
        </Button> */}
      </div>
      <TraceForm fetchTraceData={fetchTraceData} onFormSubmit={onFormSubmit} setDataField = {setDataField} setDataValue = {setDataValue} gridTrigger = {gridTrigger} setGridTrigger = {setGridTrigger}/>
      <TraceGrid
        onParentTraceGridClick={onParentTraceGridClick}
        fetchTraceData={fetchTraceData}
        traceData={traceData}
        gridTrigger={gridTrigger}
      />
      <GenericModalContainer modalKey={ModalKeys.criteriaModal}>
        <div className="bg-white h-[90vh] w-[800px] my-4 m-auto rounded-lg p-4">
          <div className="flex flex-col h-[100%]">
            <div className="flex justify-between">
              <div className="text-[20px] mb-4">SEARCH CRITERIA LIST</div>
              <div
                className="w-auto float-right cursor-pointer relative"
                onClick={() =>
                  dispatch(
                    genericModalStateUpdate({
                      modalKey: ModalKeys.criteriaModal,
                      isOpen: false
                    })
                  )
                }>
                <CloseIcon />
              </div>
            </div>

            <div>
              <Button
                onClick={addCriteriaOnClick}
                className="!bg-white !text-black !normal-case"
                variant="contained">
                Add Criteria
              </Button>
            </div>
            <CriteriaGrid trigger={trigger} />
          </div>
        </div>
      </GenericModalContainer>
      <ConformationContainer
        type="submit"
        formData={{}}
        modalId={ModalKeys.addCriteria}
        cancelObject={{ text: 'Cancel', callback: noop }}
        conformObject={{
          text: 'Add Criteria',
          callback: onConformModalSubmit
        }}
        customComponent={customComponent}
        modalText={{
          header: `Create Criteria`,
          desc: `Add Criteria Here`
        }}
      />
      <GenericModalContainer modalKey={ModalKeys.horizontalChart}>
        <HorizontalBarChart chartData={chartData} />
      </GenericModalContainer>
    </div>
  );
};

export default TraceComponent;
